interface ConfigType {
  DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: number; // number of items to show in the home page rows. Ex: watch-list
  MARKET_PLACE_ID: string;
  REACT_APP_GTM_ID: string;
  DEFAULT_TIME_ZONE: string; //https://momentjs.com/timezone/docs/#/using-timezones/formatting/

  IAS_CONNECT_ENDPOINT: string;

  API_ENDPOINT: string;
  GRAPHQL_ENDPOINT: string;
  CO_URL: string;
  MEDIA_UPLOAD_URL: string;
  SF_URL: string;

  PRIVATE_USERS: string[];

  LISTING_ELASTIC_APP: string;
  LISTING_ELASTIC_CREDENTIAL: string;
  LISTING_ELASTIC_LINK: string;
  EVENT_ELASTIC_APP: string;
  EVENT_ELASTIC_CREDENTIAL: string;
  EVENT_ELASTIC_LINK: string;

  MARKET_PLACE_ELASTIC_APP: string;
  MARKET_PLACE_ELASTIC_CREDENTIAL: string;
  MARKET_PLACE_ELASTIC_LINK: string;
  FROM_NOW_DAYS_FOR_RECENT: number;

  SIGNALR_URL: string;
  SIGNALR_CALLBACK_INVOKING_DELAY_MS: number;
  MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: number;
  MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: number;

  LINK_CONTACT_US: string;
  LINK_HOW_TO_VIDEOS: string;
  LINK_TERMS_AND_CONDITION: string;
  AUCTION_POLICY: string;
  LINK_FAQ: string;
}

interface EnvironmentVariableListType {
  [host: string]: ConfigType;
}

const HOST_NAME = window.location.hostname; // Get current domain

const configurationList: EnvironmentVariableListType = {
  default: {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",
    MARKET_PLACE_ID: "403",
    REACT_APP_GTM_ID: "GTM-NLTX2F8",
    API_ENDPOINT: "https://v2pproddealerdirectapi.rumbleon.com/api/services",
    PRIVATE_USERS: ["1087663", "1087664", "1087665"],
    GRAPHQL_ENDPOINT: "https://v2pproddealerdirectapi.rumbleon.com/graphql",
    CO_URL: "https://v4pprodcashoffer.rumbleon.com",
    MEDIA_UPLOAD_URL: "https://v4pprodcashoffer.rumbleon.com",
    SF_URL: "https://v4pprodsfapi.rumbleon.com",
    IAS_CONNECT_ENDPOINT: "https://v4pproddealer.rumbleon.com/auth/sign-in",
    LISTING_ELASTIC_APP: "v2ddpp-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-pp-rw:ycrq5ULGVJ",
    LISTING_ELASTIC_LINK:
      "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    EVENT_ELASTIC_APP: "v2ddpp-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-pp-rw:ycrq5ULGVJ",
    EVENT_ELASTIC_LINK:
      "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    MARKET_PLACE_ELASTIC_APP: "v2ddpp-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-pp-rw:ycrq5ULGVJ",
    MARKET_PLACE_ELASTIC_LINK:
      "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    SIGNALR_URL: "https://v2pproddealerdirect.rumbleon.com/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 3,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,
    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",

    // FROM_NOW_DAYS_FOR_RECENT: 30,
    // DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    // DEFAULT_TIME_ZONE: "America/Los_Angeles",
    // MARKET_PLACE_ID: "403",
    // PRIVATE_USERS: ["1086980", "1086985", "1086983"],
    // REACT_APP_GTM_ID: "GTM-NLTX2F8",
    // API_ENDPOINT: "https://v2dealerdirectapi.rumbleon.com/api/services",
    // GRAPHQL_ENDPOINT: "https://v2dealerdirectapi.rumbleon.com/graphql",
    // CO_URL: "https://cashoffer.rumbleon.com",
    // SF_URL: "https://v4pprodsfapi.rumbleon.com",
    // MEDIA_UPLOAD_URL: "https://cashoffer.rumbleon.com",
    // IAS_CONNECT_ENDPOINT: "https://dealer.rumbleon.com/auth/sign-in",
    // LISTING_ELASTIC_APP: "v2ddprod-listing",
    // LISTING_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    // LISTING_ELASTIC_LINK:
    //   "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    // EVENT_ELASTIC_APP: "v2ddprod-event",
    // EVENT_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    // EVENT_ELASTIC_LINK:
    //   "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    // MARKET_PLACE_ELASTIC_APP: "v2ddprod-marketplace",
    // MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    // MARKET_PLACE_ELASTIC_LINK:
    //   "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    // SIGNALR_URL: "https://dealerdirect.rumbleon.com/realtimeapp",
    // SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    // MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 25,
    // MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,
    // LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    // LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    // LINK_HOW_TO_VIDEOS:
    //   "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    // LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    // AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
  "v2bwdealerdirect.rmbl.bike": {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",

    MARKET_PLACE_ID: "403",
    REACT_APP_GTM_ID: "GTM-NLTX2F8",

    API_ENDPOINT: "https://v2bwdealerdirectapi.rmbl.bike/api/services",
    GRAPHQL_ENDPOINT: "https://v2bwdealerdirectapi.rmbl.bike/graphql",
    CO_URL: "https://v4pprodcashoffer.rumbleon.com",
    MEDIA_UPLOAD_URL: "",
    SF_URL: "",
    PRIVATE_USERS: [],

    IAS_CONNECT_ENDPOINT: "https://v4qadealer.rumbleon.com/auth/sign-in",

    LISTING_ELASTIC_APP: "dealerdirectdev-bw-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-dev-bw-ro:fJ4wEnS8xp",
    LISTING_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    EVENT_ELASTIC_APP: "dealerdirectdev-bw-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-dev-bw-ro:fJ4wEnS8xp",
    EVENT_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    MARKET_PLACE_ELASTIC_APP: "dealerdirectdev-bw-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-dev-bw-ro:fJ4wEnS8xp",
    MARKET_PLACE_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    SIGNALR_URL: "https://v2bwdealerdirect.rmbl.bike/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 25,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,

    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
  "v2devdealerdirect.rumbleon.com": {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",

    MARKET_PLACE_ID: "403",
    REACT_APP_GTM_ID: "GTM-NLTX2F8",

    API_ENDPOINT: "https://v2devdealerdirectapi.rumbleon.com/api/services",
    GRAPHQL_ENDPOINT: "https://v2devdealerdirectapi.rumbleon.com/graphql",
    CO_URL: "https://v4devcashoffer.rumbleon.com",
    MEDIA_UPLOAD_URL: "https://v4devcashoffer.rumbleon.com",
    SF_URL: "https://v4devsfapi.rumbleon.com",
    PRIVATE_USERS: [],

    IAS_CONNECT_ENDPOINT: "https://v4devdealer.rumbleon.com/auth/sign-in",

    LISTING_ELASTIC_APP: "v2dddev-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-dev-qa-rw:UmdmTD6CSB",
    LISTING_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    EVENT_ELASTIC_APP: "v2dddev-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-dev-qa-rw:UmdmTD6CSB",
    EVENT_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    MARKET_PLACE_ELASTIC_APP: "v2dddev-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-dev-qa-rw:UmdmTD6CSB",
    MARKET_PLACE_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    SIGNALR_URL: "https://v2bwdealerdirect.rmbl.bike/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 25,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,

    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
  "demodealerdirect.rumbleon.com": {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",
    MARKET_PLACE_ID: "403",
    REACT_APP_GTM_ID: "GTM-NLTX2F8",
    PRIVATE_USERS: [],
    API_ENDPOINT: "https://demodealerdirectapi.rumbleon.com/api/services",
    GRAPHQL_ENDPOINT: "https://demodealerdirectapi.rumbleon.com/graphql",
    CO_URL: "https://democashoffer.rumbleon.com",
    MEDIA_UPLOAD_URL: "https://democashoffer.rumbleon.com",
    SF_URL: "https://demosfapi.rumbleon.com",
    IAS_CONNECT_ENDPOINT: "https://demodealer.rumbleon.com/auth/sign-in",
    LISTING_ELASTIC_APP: "v2dddemo-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-demo-rw:WBshLp57Cd",
    LISTING_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    EVENT_ELASTIC_APP: "v2dddemo-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-demo-rw:WBshLp57Cd",
    EVENT_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    MARKET_PLACE_ELASTIC_APP: "v2dddemo-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-demo-rw:WBshLp57Cd",
    MARKET_PLACE_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    SIGNALR_URL: "https://demodealerdirect.rumbleon.com/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 3,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,
    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
  "v2qadealerdirect.rumbleon.com": {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",

    MARKET_PLACE_ID: "403",
    REACT_APP_GTM_ID: "GTM-NLTX2F8",
    PRIVATE_USERS: [],

    API_ENDPOINT: "https://v2qadealerdirectapi.rumbleon.com/api/services",
    GRAPHQL_ENDPOINT: "https://v2qadealerdirectapi.rumbleon.com/graphql",
    CO_URL: "https://v4qacashoffer.rumbleon.com",
    MEDIA_UPLOAD_URL: "https://v4qacashoffer.rumbleon.com",
    SF_URL: "https://v4qadsfapi.rumbleon.com",

    IAS_CONNECT_ENDPOINT: "https://ddv2qadealer.rumbleon.com/auth/sign-in",

    LISTING_ELASTIC_APP: "v2ddqa-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-dev-qa-rw:UmdmTD6CSB",
    LISTING_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    EVENT_ELASTIC_APP: "v2ddqa-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-dev-qa-rw:UmdmTD6CSB",
    EVENT_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    MARKET_PLACE_ELASTIC_APP: "v2ddqa-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-dev-qa-rw:UmdmTD6CSB",
    MARKET_PLACE_ELASTIC_LINK:
      "https://f083d17c0b344911a8d6deeca1ef4659.us-east-1.aws.found.io:9243",

    SIGNALR_URL: "https://v2qadealerdirect.rumbleon.com/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 3,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,

    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
  "v2pproddealerdirect.rumbleon.com": {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",
    MARKET_PLACE_ID: "403",
    REACT_APP_GTM_ID: "GTM-NLTX2F8",
    PRIVATE_USERS: ["1087663", "1087664", "1087665"],
    API_ENDPOINT: "https://v2pproddealerdirectapi.rumbleon.com/api/services",
    GRAPHQL_ENDPOINT: "https://v2pproddealerdirectapi.rumbleon.com/graphql",
    CO_URL: "https://v4pprodcashoffer.rumbleon.com",
    MEDIA_UPLOAD_URL: "https://v4pprodcashoffer.rumbleon.com",
    SF_URL: "https://v4pprodsfapi.rumbleon.com",
    IAS_CONNECT_ENDPOINT: "https://v4pproddealer.rumbleon.com/auth/sign-in",
    LISTING_ELASTIC_APP: "v2ddpp-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-pp-rw:ycrq5ULGVJ",
    LISTING_ELASTIC_LINK:
      "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    EVENT_ELASTIC_APP: "v2ddpp-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-pp-rw:ycrq5ULGVJ",
    EVENT_ELASTIC_LINK:
      "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    MARKET_PLACE_ELASTIC_APP: "v2ddpp-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-pp-rw:ycrq5ULGVJ",
    MARKET_PLACE_ELASTIC_LINK:
      "https://dffc02afc6ba4a4b8ff3dafb6e86a20d.us-east-1.aws.found.io:9243",
    SIGNALR_URL: "https://v2pproddealerdirect.rumbleon.com/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 3,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,

    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
  "v2dealerdirect.rumbleon.com": {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",
    MARKET_PLACE_ID: "403",
    REACT_APP_GTM_ID: "GTM-NLTX2F8",
    PRIVATE_USERS: ["1086980", "1086985", "1086983"],
    API_ENDPOINT: "https://v2dealerdirectapi.rumbleon.com/api/services",
    GRAPHQL_ENDPOINT: "https://v2dealerdirectapi.rumbleon.com/graphql",
    CO_URL: "https://cashoffer.rumbleon.com",
    SF_URL: "https://sfapi.rumbleon.com",
    MEDIA_UPLOAD_URL: "https://cashoffer.rumbleon.com",
    IAS_CONNECT_ENDPOINT: "https://ddv2dealer.rumbleon.com/auth/sign-in",
    LISTING_ELASTIC_APP: "v2ddprod-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    LISTING_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    EVENT_ELASTIC_APP: "v2ddprod-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    EVENT_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    MARKET_PLACE_ELASTIC_APP: "v2ddprod-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    MARKET_PLACE_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    SIGNALR_URL: "https://v2dealerdirect.rumbleon.com/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 25,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,
    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
  "dealerdirect.rumbleon.com": {
    FROM_NOW_DAYS_FOR_RECENT: 30,
    DEFAULT_ITEMS_PER_ROW_IN_HOME_PAGE: 12,
    DEFAULT_TIME_ZONE: "America/Los_Angeles",
    MARKET_PLACE_ID: "403",
    PRIVATE_USERS: ["1086980", "1086985", "1086983"],
    REACT_APP_GTM_ID: "GTM-NLTX2F8",
    API_ENDPOINT: "https://v2dealerdirectapi.rumbleon.com/api/services",
    GRAPHQL_ENDPOINT: "https://v2dealerdirectapi.rumbleon.com/graphql",
    CO_URL: "https://cashoffer.rumbleon.com",
    SF_URL: "https://sfapi.rumbleon.com",
    MEDIA_UPLOAD_URL: "https://cashoffer.rumbleon.com",
    IAS_CONNECT_ENDPOINT: "https://dealer.rumbleon.com/auth/sign-in",
    LISTING_ELASTIC_APP: "v2ddprod-listing",
    LISTING_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    LISTING_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    EVENT_ELASTIC_APP: "v2ddprod-event",
    EVENT_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    EVENT_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    MARKET_PLACE_ELASTIC_APP: "v2ddprod-marketplace",
    MARKET_PLACE_ELASTIC_CREDENTIAL: "roias-prod-rw:WBshLp57Cd",
    MARKET_PLACE_ELASTIC_LINK:
      "https://b9bff4f64d8c49488791b9c344a8d05f.us-east-1.aws.found.io:9243",
    SIGNALR_URL: "https://dealerdirect.rumbleon.com/realtimeapp",
    SIGNALR_CALLBACK_INVOKING_DELAY_MS: 6000,
    MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL: 25,
    MINIMUM_MARKET_PLACES_TO_VIEW_MARKEE: 6,
    LINK_CONTACT_US: "https://odoo.rumbleon.com/page/contactus",
    LINK_FAQ: "https://odoo.rumbleon.com/page/faq",
    LINK_HOW_TO_VIDEOS:
      "https://odoo.rumbleon.com/slides/how-to-videos-1/video",
    LINK_TERMS_AND_CONDITION: "https://odoo.rumbleon.com/page/user-agreement",
    AUCTION_POLICY: "https://odoo.rumbleon.com/page/auctionpolicy",
  },
};

interface GetConfig extends ConfigType {
  MARKET_PLACE_ELASTIC_URL: string;
  EVENT_ELASTIC_URL: string;
  LISTING_ELASTIC_URL: string;
}

function getConfig(): GetConfig {
  let configuration = configurationList.default;

  if (configurationList[HOST_NAME]) {
    configuration = configurationList[HOST_NAME];
  }
  const updatedConfig: GetConfig = {
    ...configuration,
    MARKET_PLACE_ELASTIC_URL: `${configuration.LISTING_ELASTIC_LINK}/${configuration.LISTING_ELASTIC_APP}`,
    EVENT_ELASTIC_URL: `${configuration.EVENT_ELASTIC_LINK}/${configuration.EVENT_ELASTIC_APP}`,
    LISTING_ELASTIC_URL: `${configuration.LISTING_ELASTIC_LINK}/${configuration.LISTING_ELASTIC_APP}`,
  };

  return updatedConfig;
}

const environmentVariableList = getConfig();
export default environmentVariableList;
