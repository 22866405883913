import { Col } from "antd";
import React from "react";
import { css } from "@emotion/core";

interface Props {
  title: string;
  detail: string;
  isEmail: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
export const CustomDetailView: React.FC<Props> = ({
  title,
  detail,
  isEmail,
  onChange,
}) => {
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <div
        css={css`
          background: #eeeeee 0% 0% no-repeat padding-box;
          border-radius: 4px 4px 0px 0px;
          opacity: 1;
          text-align: center;
          padding: 12px 12px 0px 12px;
          margin: 10px;
          border-bottom-style: solid;
          border-bottom-width: 1px;
          align-items: center;
          justify-content: center;
        `}
      >
        <p
          css={css`
            color: var(--light-charcoal);
            text-align: left;
            font: normal normal normal 12px Interstate;
            letter-spacing: 0px;
            color: #4c4c4c;
            text-transform: uppercase;
            opacity: 1;
            margin-bottom: 0px;
            margin-top: -4px;
          `}
        >
          {title}
        </p>
        <input
          css={css`
            height: auto;
            width: 100%;
            color: var(--dark-charcoal);
            text-align: left;
            letter-spacing: 0.13px;
            font: normal normal normal 16px AktivGroteskRegular;
            color: #2b2b2b;
            opacity: 1;
            text-transform: none;
            border: 0px;
            background: transparent;
            align-items: left;
            justify-content: center;
            outline: none !important;
            padding: 6px 0 10px;
          `}
          style={{ justifyItems: "left" }}
          type={isEmail ? "email" : "tel"}
          placeholder={detail === "" ? "Enter your contact number" : detail}
          value={detail}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
      </div>
    </div>
  );
};
