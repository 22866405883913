import _ from "lodash";
import env from "../config/env";

export default async function getRefreshToken(
  refreshToken: string,
  apiKey: string
) {
  try {
    const fetchResult = await fetch(env.GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        query: `query {
          loginResponse(mpId: 403, refreshToken: "${refreshToken}", generateRefreshToken: true) {
            apiKey
            expiresOn
            mpId
            refreshToken
            user {
              accountId
              accountNumber
              marketplaceId
              aaRepId
              username
              userId
              firstName
              lastName
              accountList  {
                accountId
                accountName
                accountNumber
                userId
                enableSelling
                badgeNumber
                city
                state
                paymentTypesArray
                defaultPaymentType
                enableBidding
                isPrimaryAccount
              }
            }
            wsMessage
            wsStatus
          }
        }`,
      }),
    });
    const response = await fetchResult.json();
    const loginResponse = _.get(response, "data.loginResponse", null);
    if (loginResponse && loginResponse.refreshToken) {
      return loginResponse;
    }
    return null;
  } catch (error) {
    return null;
  }
}
