import * as React from "react";

import { css } from "@emotion/core";

interface Props {
  title: string;
  detail: string;
}
const DetailTag: React.FC<Props> = ({ title, detail }) => {
  return (
    <div
      css={css`
        width: 100%;
        margin-top: 10px;
      `}
    >
      <p
        css={css`
          text-align: left;
          font: normal normal normal 14px Interstate;
          letter-spacing: 0px;
          color: #4c4c4c;
          text-transform: uppercase;
          opacity: 1;
        `}
      >
        {title}
      </p>
      <p
        css={css`
          text-align: left;
          font: normal normal normal 16px AktivGroteskRegular;
          letter-spacing: 0.13px;
          color: #2b2b2b;
          opacity: 1;
          word-break: break-all;
          margin-top: -10px !important;
        `}
      >
        {detail}
      </p>
    </div>
  );
};

export default DetailTag;
