//@ts-nocheck

import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useRef,
} from "react";

export const SET_CURRENT_STEP = "SET_CURRENT_STEP",
  SET_COMPLETED_STEP = "SET_COMPLETED_STEP",
  SET_DECODE_DATA = "SET_DECODE_DATA",
  SET_APPRAISAL_REF = "SET_APPRAISAL_REF",
  SET_FORM_DATA = "SET_FORM_DATA",
  SET_SELECTED_TRIM_OR_MODEL_LIST = "SET_SELECTED_TRIM_OR_MODEL_LIST",
  SET_ATTACHMENTS = "SET_ATTACHMENTS",
  SET_PROMO_CODE = "SET_PROMO_CODE",
  SET_DECODE_REQUEST = "SET_DECODE_REQUEST",
  SET_CREATE_REQUEST = "SET_CREATE_REQUEST",
  SET_UPDATE_REQUEST = "SET_UPDATE_REQUEST",
  SET_ASSIGN_REQUEST = "SET_ASSIGN_REQUEST",
  SET_ERROR = "SET_ERROR",
  SET_COLOR_LIST = "SET_COLOR_LIST",
  RESET_DECODE_DATA = "RESET_DECODE_DATA",
  RESET_APPRAISAL_REF = "RESET_APPRAISAL_REF",
  RESET_FORM_DATA = "RESET_FORM_DATA",
  RESET_ERROR = "RESET_ERROR",
  RESET_STATE = "RESET_STATE";

const initialState = {
  currentStep: 1,
  completedSteps: [],
  decodeData: null,
  appraisalRefNo: null,
  formData: null,
  selectedTrimOrModelList: null, //trim or model in step 1
  attachments: [],
  promoCode: "",
  decodeRequest: "idle",
  createRequest: "idle",
  updateRequest: "idle",
  assignRequest: "idle",
  error: null,
  colorList: [],
};

const CashOfferReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case SET_COMPLETED_STEP:
      return {
        ...state,
        completedSteps: action.payload,
      };
    case SET_DECODE_DATA:
      return {
        ...state,
        decodeData: action.payload,
      };
    case SET_APPRAISAL_REF:
      return {
        ...state,
        appraisalRefNo: action.payload,
      };
    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case SET_SELECTED_TRIM_OR_MODEL_LIST:
      return {
        ...state,
        selectedTrimOrModelList: action.payload,
      };
    case SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case SET_PROMO_CODE:
      return {
        ...state,
        promoCode: action.payload,
      };
    case SET_DECODE_REQUEST:
      return {
        ...state,
        decodeRequest: action.payload,
      };
    case SET_CREATE_REQUEST:
      return {
        ...state,
        createRequest: action.payload,
      };
    case SET_UPDATE_REQUEST:
      return {
        ...state,
        updateRequest: action.payload,
      };
    case SET_ASSIGN_REQUEST:
      return {
        ...state,
        assignRequest: action.payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_COLOR_LIST:
      return {
        ...state,
        colorList: action.payload,
      };
    case RESET_DECODE_DATA:
      return {
        ...state,
        decodeData: null,
      };
    case RESET_APPRAISAL_REF:
      return {
        ...state,
        appraisalRefNo: null,
      };
    case RESET_FORM_DATA:
      return {
        ...state,
        formData: null,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

const CashOfferContext = createContext<any>(null);

//use this to get latest state
const useEnhancedReducer = (reducer, initState) => {
  const lastState = useRef(null);
  const getState = useCallback(() => lastState.current, []);
  return [
    ...useReducer(
      (state, action) => (lastState.current = reducer(state, action)),
      initState
    ),
    getState,
  ];
};

export const CashOfferContextProvider = ({ children }: any) => {
  const [state, dispatch, getState] = useEnhancedReducer(
    CashOfferReducer,
    initialState
  );

  return (
    <CashOfferContext.Provider
      value={{
        state,
        dispatch,
        getState,
      }}
    >
      {children}
    </CashOfferContext.Provider>
  );
};

export const useCashOfferContext = () => {
  const context = useContext(CashOfferContext);
  if (context === undefined) {
    throw new Error("Something is wrong with useCashOfferContext!");
  }
  return context;
};
