import * as React from "react";

import { Icon, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";

import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";
import getActiveKeys from "./getActiveKeys";
import { useAuthPopupState } from "../../../context/AuthPopupContext";

const menuCss = css`
  border: none !important;
  .ant-menu-submenu-title {
    color: #2b2b2b !important;
    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-size: 18px;
  }
  .ant-menu-item > a {
    color: #2b2b2b !important;
    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-size: 18px;
  }
  .ant-menu-item {
    border: none !important;
    background: white !important;
    text-align: center;
  }
  @media (max-width: 1280px) {
    .ant-menu-item {
      padding: 0 8px !important;
    }
    .ant-menu-submenu-title {
      padding: 0 8px !important;
    }
  }
  .ant-menu-item-selected > a {
    border-bottom: 2px solid #1b51cf !important;
    font-weight: bold !important;
  }
  .ant-menu-item.ant-menu-item-selected {
    background: white !important;
  }
  .ant-menu-submenu > a {
    color: #2b2b2b !important;
    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
    font-weight: 400;
    font-size: 18px;
  }
  .ant-menu-submenu {
    border: none !important;
    background: white !important;
    text-align: center;
    max-width: 110px;
    margin-right: 10px;
}
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title span{
    border-bottom: 2px solid #1b51cf !important;
    padding-bottom: 3px;
  }
  .ant-menu-submenu-selected > a {
    border-bottom: 2px solid #1b51cf !important;
    font-weight: bold !important;
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #a6a7a9;
  }

  line-height: 25px !important;
`;
const SubMenu = Menu.SubMenu;
const MainMenu: React.FC = () => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  let location = useLocation();

  React.useEffect(() => {
    const { pathname } = location;
    const activeKeys = getActiveKeys(pathname);
    setSelectedKeys(activeKeys);
  }, [location.pathname]);

  const { isLoggedIn } = useAuthPopupState();

  if (!isLoggedIn) {
    return null;
  }
  return (
    <Menu mode="horizontal" css={menuCss} selectedKeys={selectedKeys}>
      <Menu.Item key="home" id="home-menu">
        <Link id="home-key" to="/">
          Home
        </Link>
      </Menu.Item>
      <Menu.Item key="buy" id="browse-menu">
        <Link id="browse-key" to="/buy">
          Browse
        </Link>
      </Menu.Item>
      <Menu.Item key="events">
        <Link to="/events">Events</Link>
      </Menu.Item>
      {/* <Menu.Item key="market-place">
        <Link to="/market-place">Marketplaces</Link>
      </Menu.Item> */}
      <Menu.Item key="watch-list">
        <Link to="/watch-list">Watchlist</Link>
      </Menu.Item>
      <Menu.Item key="run-list">
        <Link to="/run-list?page=1">Run Lists</Link>
      </Menu.Item>
      <Menu.Item key="buyer">
        <Link to="/buyer?tab=bids&page=1">
          <span id="buyer-menu-id"> Buyer</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="seller">
        <Link to="/seller?tab=listed&page=1">
          <span id="seller-menu"> Seller</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="history">
        <Link to="/history">History</Link>
      </Menu.Item>
      {/* <SubMenu
        key="activity"
        title={
          <>
            <span id="activity-menu-id">Activity</span>{" "}
            <Icon type="caret-down" />
          </>
        }
      >
        <Menu.Item key="history">
          <Link to="/history">History</Link>
        </Menu.Item>
        <Menu.Item key="buyer">
          <Link to="/buyer?tab=bids&page=1">
            <span id="buyer-menu-id"> Buyer</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="seller">
          <Link to="/seller?tab=listed&page=1">
            <span id="seller-menu"> Seller</span>
          </Link>
        </Menu.Item>
      </SubMenu> */}
    </Menu>
  );
};

export default MainMenu;
