/* eslint-disable react/jsx-key */

import * as React from "react";

import { Col, Modal, Radio, Row, Spin, message, notification } from "antd";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import { AlertDropdown } from "../AlertList/AlertDropdown";
import { CustomDetailView } from "../../UI/CustomDetailView";
import { RadioChangeEvent } from "antd/lib/radio";
import _ from "lodash";
import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";
import { loader } from "graphql.macro";
import { useAuthInfo } from "../../../utils/customHooks";
import { useState } from "react";
import { validateEmail } from "../../../utils/validate";

const PROFILE_QUERY = loader("../profile.graphql");
const ADD_ALERT = loader("../addAlerts.graphql");

export const ALERT_TYPES = [
  { id: 1007, description: "Sold" },
  { id: 1008, description: "Purchased" },
  { id: 1004, description: "Offer Received" },
  { id: 1005, description: "Offer Placed" },
  { id: 1028, description: "Bid Received" },
  { id: 1029, description: "Bid Placed" },
  { id: 1006, description: "Outbid Alert" },
  { id: 1016, description: "Watchlist Item Live Alert" },
  { id: 3005, description: "Transaction Status Update" },
  // { id: 1002, description: "Wishlist Alert" },
];

export type AccountProp = {
  accountName: string;
  regDate: string;
};

export type ProfileProp = {
  firstName: string;
  lastName: string;
  phone: string;
  mobile: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  createdOn: string;
  accountList: AccountProp[];
};

export type AlertProp = {
  alertId: number;
  alertTypeId: number;
  deviceAddress: string;
  deviceModel: string;
  deviceName: string;
  deviceTypeId: number;
  statusId: number;
};
type Props = {
  onCancel: () => void;
  alertId?: number;
};
const AlertFormModal: React.FC<Props> = ({ onCancel, alertId }) => {
  let [profileData] = useState<any>({});

  let [contactValue, setContactValue] = useState<number>(0);
  const [alertTypeId, setAlertTypeId] = useState<number | null>(null);
  const { mpId, apiKey } = useAuthInfo();
  const [emailText, setEmailText] = React.useState("");
  const [mobileText, setMobileText] = React.useState("");
  const [alertType, setAlertType] = React.useState(false);

  const [addAlert, { loading: addAlertLoading }] = useLazyQuery(ADD_ALERT, {
    fetchPolicy: "network-only",
    onCompleted: (response) => {
      const wsStatus = _.get(response, "alertResponse.wsStatus", null);
      if (wsStatus === "Error") {
        const errorMessage = _.get(
          response,
          "alertResponse.wsMessage",
          "Something went wrong while updating"
        );
        notification.open({
          message: "Error",
          description: errorMessage,
          type: "error",
        });
      } else {
        notification.open({
          message: "Success",
          description: "Successfully created",
          type: "success",
        });
        onCancel();
      }
    },
    onError: () => {
      notification.open({
        message: "Error",
        description: "Something went wrong while adding new alert",
        type: "error",
      });
    },
  });

  const { data: userResponse } = useQuery<{
    userResponse: { user: ProfileProp };
  }>(PROFILE_QUERY, {
    variables: {
      mpId: mpId,
      apiKey: apiKey,
    },
    onCompleted: (userResponse) => {
      if (userResponse) {
        profileData = userResponse.userResponse.user;
        setEmailText(profileData.email);
        setMobileText(profileData.mobile);
      }
    },
    onError: () => {
      notification.open({
        message: "Error",
        description: "Something went wrong while getting profile",
        type: "warning",
      });
    },
  });
  if (userResponse) {
    profileData = userResponse.userResponse.user;
  }

  React.useEffect(() => {
    if (alertId) {
      setAlertTypeId(alertId);
    }
  }, []);

  const onChangeRadio = (e: RadioChangeEvent) => {
    setContactValue(e.target.value);
  };

  return (
    <Modal
      title="Add Alert"
      css={css`
        text-align: left;
        font: normal normal normal 24px InterstateBold;
        letter-spacing: 0px;
        color: #2b2b2b;
        text-transform: uppercase;
        opacity: 1;
        @media (max-width: ${breakpoints.sm}) {
          padding-top: 100px;
        }
        .ant-modal-header {
          min-height: 53px;
        }
      `}
      visible={true}
      closable={false}
      onOk={() => {
        if (alertTypeId && contactValue) {
          if (contactValue === 1 && !emailText.trim()) {
            message.warn("Email address is required");
          } else if (contactValue === 1 && !validateEmail(emailText)) {
            message.warn("Email is not valid");
          } else if (contactValue !== 1 && !mobileText.trim()) {
            message.warn("Phone number is required");
          } else {
            addAlert({
              variables: {
                apiKey: apiKey,
                mpId: mpId,
                deviceAddress:
                  contactValue === 1 ? `${emailText}` : `${mobileText}`,
                alertTypeIds: `${alertTypeId}`,
                deviceTypeId: contactValue,
              },
            });
            setContactValue(0);
            setAlertType(!alertType);
          }
        }
      }}
      onCancel={() => {
        onCancel();
        setContactValue(0);
        setAlertType(!alertType);
      }}
      okText={"Create"}
      okButtonProps={{
        style: {
          backgroundColor: "white",
          color: alertTypeId && contactValue ? "#1B51CF" : "#9E9E9E",
          border: 0,
          boxShadow: "none",
          fontFamily: "AktivGroteskRegular",
        },
      }}
      cancelButtonProps={{
        style: {
          backgroundColor: "white",
          color: "#2B2B2B",
          border: 0,
          boxShadow: "none",
          fontFamily: "AktivGroteskRegular",
        },
      }}
    >
      <Spin spinning={addAlertLoading}>
        <Row
          css={css`
            width: 100%;
            margin-top: -30px;
            @media (max-width: ${breakpoints.sm}) {
              margin-top: -20px;
            }
          `}
          gutter={0}
        >
          <AlertDropdown
            selectedAlertType={_.find(ALERT_TYPES, (o) => {
              return o.id === alertId;
            })}
            onChange={(id) => {
              setAlertTypeId(id);
            }}
            alertType={alertType}
          />
          <Col
            lg={9}
            css={css`
              background: #ffffff;
              padding: 10px;
            `}
          >
            <p
              css={css`
                color: var(--light-charcoal);
                text-align: left;
                font: normal normal normal 14px Interstate;
                letter-spacing: 0px;
                color: #4c4c4c;
                text-transform: uppercase;
                opacity: 1;
              `}
            >
              Contact Method
            </p>
            <Radio.Group onChange={onChangeRadio} value={contactValue}>
              <Radio
                css={css`
                  text-transform: none;
                  color: var(--dark-charcoal);
                  font: normal normal normal 14px AktivGroteskRegular;
                  letter-spacing: 0.13px;
                  color: #2b2b2b;
                  opacity: 1;
                `}
                value={1}
              >
                Email
              </Radio>
              <Radio
                css={css`
                  text-transform: none;
                  color: var(--dark-charcoal);
                  font: normal normal normal 14px AktivGroteskRegular;
                  letter-spacing: 0.13px;
                  color: #2b2b2b;
                  opacity: 1;
                `}
                value={6}
              >
                Text
              </Radio>
            </Radio.Group>
          </Col>
        </Row>
        {contactValue === 1 && (
          <div
            css={css`
              margin-top: 15px;
            `}
          >
            <CustomDetailView
              title={"Email Address"}
              detail={emailText}
              isEmail={true}
              onChange={(e) => setEmailText(e.target.value)}
            />
          </div>
        )}
        {contactValue === 6 && (
          <div
            css={css`
              margin-top: 15px;
            `}
          >
            <CustomDetailView
              title={"Phone Number"}
              detail={mobileText}
              isEmail={false}
              onChange={(e) => setMobileText(e.target.value)}
            />
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default AlertFormModal;
