import * as React from "react";

import { css } from "@emotion/core";
import env from "../../../config/env";

interface PropType {
  bidderCount: number;
}
const BidderCount: React.FC<PropType> = ({ bidderCount }) => {
  if (bidderCount <= env.MINIMUM_ONLINE_BIDDERS_TO_DISPLAY_LABEL) {
    return null;
  }
  return (
    <div
      css={css`
        border: 1px solid #c1ec77;
        padding: 4px 6px;
        border-radius: 10px;
        text-align: center;
        line-height: 1;
        font-size: 12px;
        background: #7cc105;
        margin-right: 16px;
        color: white;
      `}
    >
      <span> Online </span>
      <span>
        {" "}
        Bidders: <b> {bidderCount} </b>
      </span>
    </div>
  );
};

export default BidderCount;
