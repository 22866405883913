const hasValidCharactersLength = (value: string) => value.length >= 6;

const hasOneLowercaseCharacter = (value: string) => {
  const regex = /^(?=.*[a-z]).+$/;
  return regex.test(value);
};

const hasOneUppercaseCharacter = (value: string) => {
  const regex = /^(?=.*[A-Z]).+$/;

  return regex.test(value);
};

const hasOneNumeric = (value: string) => {
  const regex = /^(?=.*\d).+$/;

  return regex.test(value);
};

const validatePasswordPattern = (value: string) => {
  return {
    validLength: hasValidCharactersLength(value),
    oneLowerCase: hasOneLowercaseCharacter(value),
    oneUpperCase: hasOneUppercaseCharacter(value),
    oneNumeric: hasOneNumeric(value),
  };
};

export const validatePassword = (value: string) => {
  let message = "";

  if (value !== undefined) {
    const result = validatePasswordPattern(value);

    if (!result.validLength) {
      message += "six characters";
    }
    if (!result.oneLowerCase) {
      message.length > 0
        ? (message += ", one lowercase character")
        : (message += "one lowercase character");
    }
    if (!result.oneUpperCase) {
      message.length > 0
        ? (message += ", one uppercase character")
        : (message += "one uppercase character");
    }
    if (!result.oneNumeric) {
      message.length > 0
        ? (message += ", one numeric character")
        : (message += "one numeric character");
    }
    if (message.length > 0) {
      message += ".";
    }
  }

  return message;
};

export const validateEmail = (email: string) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return true;
  }
  return false;
};
