/* eslint-disable react/jsx-key */
import "../../../index.css";

import * as React from "react";

import { Col, Row, Spin } from "antd";

import AccountItemCard from "./AccountItemCard";
import { AccountProp } from "../ProfilePage";
import _ from "lodash";
import { css } from "@emotion/core";
import moment from "moment";

interface Props {
  profileData: {
    accountList: AccountProp[];
  };
  loadingUserResponse: boolean;
}

const AccountListTabView: React.FC<Props> = ({
  profileData,
  loadingUserResponse,
}) => {
  let accountList: AccountProp[] = [];
  if (profileData && profileData.accountList) {
    accountList = profileData.accountList;
  }
  return (
    <div
      css={css`
        @media (max-width: 600px) {
          width: 85% !important;
        }
        left: 498px;
        width: 608px;
        height: auto;
        background: var(--white) 0% 0% no-repeat padding-box;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px #00000029;
        border-radius: 4px;
        opacity: 1;
      `}
      style={{
        width: "60%",
        backgroundColor: "#ffffff",
        left: 0,
        right: 0,
        margin: "0 auto",
        marginBottom: 10,
      }}
    >
      {loadingUserResponse ? (
        <Spin spinning={loadingUserResponse} />
      ) : (
        accountList.map((item: AccountProp, key: any) => {
          return <AccountItemCard item={item} />;
        })
      )}
    </div>
  );
};

export default AccountListTabView;
