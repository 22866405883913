export const breakpoints = {
  sm: "576px",
  md: "875px",
  mid: "1488px",
  lg: "1800px",

  smInt: 576,
  mdInt: 875,
  midInt: 1488,
  lgInt: 1800,
};
export const fontFamilies = {
  AktivGroteskBold: "AktivGroteskBold",
  AktivGroteskMedium: "AktivGroteskMedium",
  AktivGroteskRegular: "AktivGroteskRegular",
  InterstateBold: "InterstateBold",
};

export const colorList = [
  {
    colorId: 1,
    colorName: "Black",
  },
  {
    colorId: 2,
    colorName: "Blue",
  },
  {
    colorId: 3,
    colorName: "Red",
  },
  {
    colorId: 4,
    colorName: "Brown",
  },
  {
    colorId: 5,
    colorName: "White",
  },
  {
    colorId: 6,
    colorName: "Gray",
  },
  {
    colorId: 7,
    colorName: "Silver",
  },
  {
    colorId: 8,
    colorName: "Green",
  },
  {
    colorId: 9,
    colorName: "Orange",
  },
  {
    colorId: 10,
    colorName: "Yellow",
  },
  {
    colorId: 11,
    colorName: "Gold",
  },
  {
    colorId: 12,
    colorName: "Purple",
  },
  {
    colorId: 13,
    colorName: "Maroon",
  },
  {
    colorId: 14,
    colorName: "Teal",
  },
  {
    colorId: 15,
    colorName: "Beige",
  },
  {
    colorId: 16,
    colorName: "Tan",
  },
  {
    colorId: 17,
    colorName: "Copper",
  },
];

export const titleStatusList = [
  {
    titleStatus: "Clean",
    titleStatusId: 1,
  },
  {
    titleStatus: "Junk",
    titleStatusId: 2,
  },
  {
    titleStatus: "Total Loss",
    titleStatusId: 3,
  },
  {
    titleStatus: "Lemon",
    titleStatusId: 4,
  },
  {
    titleStatus: "Grey Market",
    titleStatusId: 5,
  },
  {
    titleStatus: "Flooded",
    titleStatusId: 6,
  },
  {
    titleStatus: "Reconstructed",
    titleStatusId: 7,
  },
  {
    titleStatus: "Non-Actual Miles",
    titleStatusId: 8,
  },
  {
    titleStatus: "Salvage",
    titleStatusId: 9,
  },
  {
    titleStatus: "Other",
    titleStatusId: 10,
  },
];

export const listingStatusList = [
  {
    id: 1,
    title: "Incomplete",
  },
  {
    id: 2,
    title: "In Review",
  },
  {
    id: 3,
    title: "Approved",
  },
  {
    id: 4,
    title: "Sold",
  },
  {
    id: 5,
    title: "Denied",
  },
  {
    id: 6,
    title: "Deleted",
  },
  {
    id: 7,
    title: "Deleted",
  },
  {
    id: 8,
    title: "Cash Offer Accepted",
  },
  {
    id: 9,
    title: "Published",
  },
  {
    id: 10,
    title: "Expired",
  },
  {
    id: 11,
    title: "Denied",
  },
  {
    id: 12,
    title: "In Review",
  },
];

export const roles = {
  MODERATOR: "Moderator",
  SAFEX_ADMIN: "SafeXchange Admin",
};

export const ROLE_KEY = "Role";

export const ERROR_MESSAGE = {
  default: "Something went wrong, please try again.",
  badZip: "The zip code you entered is invalid",
  beingAppraised:
    "A vehicle with this VIN is already in our appraisal process.",
};
