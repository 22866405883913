import * as React from "react";

import { css } from "@emotion/core";

const containerCss = css`
  padding: 20px 12%;
  text-align: center;
  background: whitesmoke;
  /* Mobile style */
  @media (max-width: 992px) {
    padding: 10px;
  }
`;
const tradeMarkCss = css`
  font-size: 12px;
`;
const Footer: React.FC = () => {
  return null;
};

export default Footer;
