import "../../../index.css";

import * as React from "react";

import _ from "lodash";
import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";

interface Props {
  checkList: number[];
  showDeleteConfirm: () => void;
  showModal: () => void;
  isBottom: boolean;
}

const AlertButton: React.FC<Props> = ({
  checkList,
  showDeleteConfirm,
  showModal,
  isBottom,
}) => {
  return (
    <button
      onClick={checkList.length ? showDeleteConfirm : showModal}
      css={
        isBottom
          ? checkList.length
            ? css`
                width: max-content;
                height: 56px;
                color: white;
                padding: 0 2%;
                cursor: pointer;
                background: #99100c 0% 0% no-repeat padding-box;
                // border-radius: 28px;
                opacity: 1;
                position: fixed;
                right: 26px;
                bottom: 30px;
                border: none !important;
                outline: none !important;
                z-index: 10 !important;
              `
            : css`
                width: max-content;
                height: 56px;
                color: white;
                padding: 0 2%;
                cursor: pointer;
                background: #5c903f 0% 0% no-repeat padding-box;
                border-radius: 28px;
                opacity: 1;
                position: fixed;
                right: 26px;
                bottom: 30px;
                border: none !important;
                outline: none !important;
                z-index: 10 !important;
              `
          : checkList.length
          ? css`
              width: max-content;
              height: 56px;
              color: white;
              padding: 0 2%;
              cursor: pointer;
              background: #99100c 0% 0% no-repeat padding-box;
              // border-radius: 28px;
              opacity: 1;
              position: fixed;
              right: 26px;
              top: 96px;
              border: none !important;
              outline: none !important;
              z-index: 10 !important;
            `
          : css`
              width: max-content;
              height: 56px;
              color: white;
              padding: 0 2%;
              cursor: pointer;
              background: #5c903f 0% 0% no-repeat padding-box;
              // border-radius: 28px;
              opacity: 1;
              position: fixed;
              right: 26px;
              top: 96px;
              border: none !important;
              outline: none !important;
              z-index: 10 !important;
            `
      }
    >
      {/* <img
        src={
          checkList.length
            ? require("../images/ic_delete_24px.svg")
            : require("../images/ic_add_24px.svg")
        }
        css={css`
          width: 24px;
          height: 24px;
          background: transparent url("img/ic_add_24px.png") 0% 0% no-repeat
            padding-box;
          opacity: 1;
        `}
      /> */}

      {checkList.length ? "DELETE ALERT" : "CREATE ALERT"}
    </button>
  );
};

export default AlertButton;
