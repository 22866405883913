/* eslint-disable */

import * as React from "react";

type Action = { type: "SET_WATCH_LIST_ITEMS"; args: any };
type Dispatch = (action: Action) => void;
type State = {
  watchListItemIds: number[];
};
type WatchListProviderProps = {
  children: React.ReactNode;
};

const WatchListStateContext = React.createContext<State>({
  watchListItemIds: [],
});

const WatchListDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const WatchListReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_WATCH_LIST_ITEMS":
      return {
        ...state,
        watchListItemIds: action.args,
      };

    default:
      return state;
  }
};

const WatchListContextProvider = ({ children }: WatchListProviderProps) => {
  const [state, dispatch] = React.useReducer(WatchListReducer, {
    watchListItemIds: [],
  });

  return (
    <WatchListStateContext.Provider value={state}>
      <WatchListDispatchContext.Provider value={dispatch}>
        {children}
      </WatchListDispatchContext.Provider>
    </WatchListStateContext.Provider>
  );
};
const useWatchListState = () => {
  const context = React.useContext(WatchListStateContext);
  if (context === undefined) {
    throw new Error(
      "useWatchListContext must be used within a WatchListContextProvider"
    );
  }
  return context;
};

const useWatchListDispatch = () => {
  const context = React.useContext(WatchListDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useWatchListDispatch must be used within a WatchListContextProvider"
    );
  }
  return context;
};

export { WatchListContextProvider, useWatchListState, useWatchListDispatch };
