import "../../../index.css";

import * as React from "react";

import { Col, Row } from "antd";

import { AccountProp } from "../ProfilePage";
import _ from "lodash";
import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";
import moment from "moment";

interface Props {
  item: AccountProp;
}

const AccountItemCard: React.FC<Props> = ({ item }) => {
  return (
    <Row
      css={css`
        border-bottom: solid;
        border-bottom-width: 1px;
        border-bottom-color: #f5f5f5;
        padding-top: 18px;
        padding-left: 24px;
        padding-right: 24px;
      `}
    >
      <div>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          css={css`
            color: var(--dark-charcoal);
            text-align: left;
            background: #ffffff;
          `}
        >
          <h1
            css={css`
              letter-spacing: var(--unnamed-character-spacing-0-14);
              color: var(--dark-charcoal);
              text-align: left;
              font: normal normal normal 16px AktivGroteskBold;
              letter-spacing: 0.14px;
              color: #2b2b2b;
              opacity: 1;
            `}
          >
            {item.accountName}
          </h1>
        </Col>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          css={css`
            color: var(--dark-charcoal);
            text-align: right;
            background: #ffffff;
          `}
        >
          <p
            css={css`
              color: var(--dark-charcoal);
              text-align: right;
              font: normal normal normal 16px AktivGroteskRegular;
              letter-spacing: 0.13px;
              color: #2b2b2b;
              opacity: 1;
              word-break: break-all;
              @media (max-width: ${breakpoints.sm}) {
                text-align: left;
              }
            `}
          >
            {moment(item.regDate).format(" LLL")}
          </p>
        </Col>
      </div>
    </Row>
  );
};

export default AccountItemCard;
