import "../../../index.css";

import * as React from "react";

import { Col, Row } from "antd";

import DetailTag from "../DetailTag";
import { ProfileProp } from "../ProfilePage";
import _ from "lodash";
import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";

interface Props {
  profileData: ProfileProp;
}

const AccountDetailView: React.FC<Props> = ({ profileData }) => {
  return (
    <>
      <Row
        css={css`
          background: #ffffff;
          width: 100%;
          margin-top: 15px;
        `}
        gutter={0}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag title={"First Name"} detail={profileData.firstName} />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag title={"Last Name"} detail={profileData.lastName} />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag
            title={"Phone Number"}
            detail={profileData.phone === "" ? "--" : profileData.phone}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          css={css`
            background: #ffffff;
            @media (min-width: ${breakpoints.md}) {
              display: none;
            }
          `}
        >
          <DetailTag
            title={"Mobile number"}
            detail={profileData.mobile === "" ? "--" : profileData.mobile}
          />
        </Col>
      </Row>
      <Row
        css={css`
          background: #ffffff;
          width: 100%;
          margin-top: 15px;
        `}
        gutter={0}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          css={css`
            background: #ffffff;
            @media (max-width: ${breakpoints.md}) {
              display: none;
            }
          `}
        >
          <DetailTag
            title={"Mobile number"}
            detail={profileData.mobile === "" ? "--" : profileData.mobile}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag
            title={"Email address"}
            detail={profileData.email === "" ? "--" : profileData.email}
          />
        </Col>
      </Row>
      <div
        css={css`
          height: 1px;
          background: #2b2b2b;
          margin-top: 15px;
          margin-left: -25px;
          margin-right: -25px;
        `}
      />
      <Row
        css={css`
          background: #ffffff;
          width: 100%;
          margin-top: 15px;
        `}
        gutter={0}
      >
        <Col
          lg={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag
            title={"Address - Line 1"}
            detail={profileData.address1 === "" ? "--" : profileData.address1}
          />
        </Col>
        <Col
          lg={8}
          css={css`
            background: #ffffff;
          `}
        ></Col>
        <Col
          lg={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag
            title={"Address - Line 2"}
            detail={profileData.address2 === "" ? "--" : profileData.address2}
          />
        </Col>
      </Row>
      <Row
        css={css`
          background: #ffffff;
          width: 100%;
          margin-top: 15px;
        `}
        gutter={0}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag
            title={"City"}
            detail={profileData.city === "" ? "--" : profileData.city}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag
            title={"State"}
            detail={profileData.state === "" ? "--" : profileData.state}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={8}
          xl={8}
          css={css`
            background: #ffffff;
          `}
        >
          <DetailTag
            title={"Country"}
            detail={profileData.country === "" ? "--" : profileData.country}
          />
        </Col>
      </Row>
    </>
  );
};

export default AccountDetailView;
