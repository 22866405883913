/* eslint-disable react/jsx-key */

import "../../index.css";

import * as React from "react";

import { Layout, List, Modal, Spin, Tabs, notification } from "antd";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import AccountInfoTabView from "./InfoTab/AccountInfoTabView";
import AccountListTabView from "./AccountList/AccountListTabView";
import AlertButton from "./AlertList/AlertButton";
import AlertFormModal from "./AlertList/AlertFormModal";
import AlertItem from "./AlertList/AlertItem";
import Helmet from "react-helmet";
import _ from "lodash";
import { breakpoints } from "../../constants";
import { css } from "@emotion/core";
import { loader } from "graphql.macro";
import { useAuthInfo } from "../../utils/customHooks";
import { useHistory } from "react-router-dom";
import { useState } from "react";

const { confirm } = Modal;
const PROFILE_QUERY = loader("./profile.graphql");
const ALERT_QUERY = loader("./getAlerts.graphql");
const ADD_ALERT = loader("./addAlerts.graphql");
const UPDATE_ALERT = loader("./updateAlert.graphql");
const REMOVE_ALERT = loader("./removeAlerts.graphql");

const { Content } = Layout;

export const ALERT_TYPES = [
  { id: 1007, description: "Sold" },
  { id: 1008, description: "Purchased" },
  { id: 1004, description: "Offer Received" },
  { id: 1005, description: "Offer Placed" },
  { id: 1028, description: "Bid Received" },
  { id: 1029, description: "Bid Placed" },
  { id: 1006, description: "Outbid Alert" },
  { id: 1016, description: "Watchlist Item Live Alert" },
  { id: 3005, description: "Transaction Status Update" },
  // { id: 1002, description: "Wishlist Alert" },
];

export type AccountProp = {
  accountName: string;
  regDate: string;
  enableSelling: boolean;
};

export type ProfileProp = {
  firstName: string;
  lastName: string;
  phone: string;
  mobile: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  createdOn: string;
  accountList: AccountProp[];
};

export type AlertProp = {
  alertId: number;
  alertTypeId: number;
  deviceAddress: string;
  deviceModel: string;
  deviceName: string;
  deviceTypeId: number;
  statusId: number;
};

const ProfilePage: React.FC = () => {
  let [profileData, setProfileData] = useState<any>({});
  let [alertData, setAlertData] = useState<AlertProp[]>([]);
  let [activeTab, setActiveTab] = useState("1");
  let [checkList, onCheckList] = useState<number[]>([]);
  let [visible, setVisible] = useState<boolean>(false);
  const { mpId, apiKey } = useAuthInfo();
  const history = useHistory();

  React.useEffect(() => {
    getAlert();
  }, []);

  React.useEffect(() => {
    var params = new URLSearchParams(window.location.search).get("tab");
    switch (params) {
      case "accounts":
        setActiveTab("accounts");
        break;
      case "alerts":
        setActiveTab("alerts");
        break;
      default:
        setActiveTab("info");
        break;
    }
  }, [window.location.search]);

  const [getAlert, { loading: loadingAlertList }] = useLazyQuery(ALERT_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      mpId,
      apiKey,
    },
    onCompleted: (result) => {
      if (result && result.alertListResponse) {
        const items = result.alertListResponse.items;
        items.sort(function (x: { alertId: number }, y: { alertId: number }) {
          let a = x.alertId,
            b = y.alertId;
          return b - a;
        });
        setAlertData(items);
      }
    },
    onError: () => {
      notification.open({
        message: "Error",
        description: "Cannot Get Alerts",
        type: "warning",
      });
    },
  });

  const [updateAlert, { loading: updateAlertLoading }] = useLazyQuery(
    UPDATE_ALERT,
    {
      fetchPolicy: "network-only",
      onCompleted: () => {
        getAlert();
      },
      onError: () => {
        notification.open({
          message: "Error",
          description: "Something went wrong while updating new alert",
          type: "error",
        });
      },
    }
  );

  const [removeAlerts] = useLazyQuery(REMOVE_ALERT, {
    fetchPolicy: "network-only",
    onCompleted: () => {
      getAlert();
    },
  });

  const { loading: loadingUserResponse } = useQuery<{
    userResponse: { user: ProfileProp };
  }>(PROFILE_QUERY, {
    variables: {
      mpId: mpId,
      apiKey: apiKey,
    },
    onCompleted: (userResponse) => {
      if (userResponse) {
        profileData = _.get(userResponse, "userResponse.user", {});
        setProfileData(profileData);
      }
    },
    onError: () => {
      notification.open({
        message: "Error",
        description: "Something went wrong while getting profile",
        type: "warning",
      });
    },
  });

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  function onCheckItem(id: number) {
    const index = checkList.indexOf(id);
    let newList = _.clone(checkList);
    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList = [...newList, id];
    }
    onCheckList(newList);
  }

  const updateAlertStatus = async (isChecked: boolean, item: AlertProp) => {
    await updateAlert({
      variables: {
        apiKey: apiKey,
        mpId: mpId,
        deviceAddress: "deviceAddress",
        alertTypeId: Number(item.alertTypeId),
        alertId: item.alertId,
        deviceTypeId: item.deviceTypeId,
        statusId: isChecked ? 1 : 2,
      },
    });
  };

  function alertsListTabPane() {
    return (
      <Tabs.TabPane
        tab="Alerts"
        key="alerts"
        css={css`
          @media (max-width: ${breakpoints.sm}) {
            margin-top: -16px;
          }
        `}
      >
        {visible ? (
          <AlertFormModal
            onCancel={() => {
              setVisible(false);
              getAlert();
            }}
          />
        ) : null}

        <List
          css={css`
            @media (max-width: ${breakpoints.sm}) {
              width: 100% !important;
              padding: 16px;
            }
          `}
          style={{
            width: "50%",
            backgroundColor: "#ffffff",
            left: 0,
            right: 0,
            margin: "0 auto",
            marginBottom: 10,
          }}
          loading={!alertData.length && loadingAlertList}
          itemLayout="horizontal"
          dataSource={alertData}
          renderItem={(item) => alertItemView(item)}
        />
      </Tabs.TabPane>
    );
  }

  function alertItemView(item: AlertProp) {
    let alertName: string = "--";
    ALERT_TYPES.forEach((alert) => {
      if (item.alertTypeId === alert.id) {
        return (alertName = alert.description);
      }
      return alertName;
    });

    return (
      <List.Item
        css={css`
          min-height: 100px;
          background: #ffffff;
          padding-left: 16px;
          padding-right: 16px;
          @media (max-width: ${breakpoints.sm}) {
            padding-left: 18px !important;
            padding-right: 18px !important;
          }
        `}
      >
        <AlertItem
          checkList={checkList}
          item={item}
          onCheckItem={async () => onCheckItem(item.alertId)}
          alertName={alertName}
          updateAlertStatus={updateAlertStatus}
          updateAlertLoading={updateAlertLoading}
        />
      </List.Item>
    );
  }

  function showDeleteConfirm() {
    return confirm({
      title: "Are you sure you would like to delete these alerts?",
      icon: "",
      content: "",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk() {
        removeAlerts({
          variables: {
            mpId: mpId,
            apiKey: apiKey,
            alertIds: checkList.join(","),
          },
        });
        onCheckList([]);
      },
      onCancel() {
        console.log("Cancel");
      },
      okButtonProps: {
        style: {
          backgroundColor: "white",
          color: "#99100C",
          border: 0,
          boxShadow: "none",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: "white",
          color: "#2B2B2B",
          border: 0,
          boxShadow: "none",
        },
      },
    });
  }

  function tabChange(activeKey: string) {
    setActiveTab(activeKey);
    history.replace(`/profile?tab=${activeKey}`);
  }

  if (!profileData) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Profile</title>
        <meta name="description" content="Profile" />
      </Helmet>

      <Layout>
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            align-self: flex-start;
            position: fixed;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-left: 24px;
            top: 80px;
            @media (max-width: ${breakpoints.mid}) {
              display: none;
            }
            z-index: 4;
          `}
        >
          <h1
            css={css`
              color: var(--dark-charcoal);
              text-align: left;
              font: normal normal bold 24px Interstate;
              letter-spacing: 0px;
              color: #2b2b2b;
              text-transform: uppercase;
              opacity: 1;
            `}
          >
            Profile
          </h1>
        </div>
        {activeTab === "alerts" && (
          <div
            css={css`
              @media (max-width: ${breakpoints.sm}) {
                display: none;
              }
            `}
          >
            <AlertButton
              checkList={checkList}
              showDeleteConfirm={showDeleteConfirm}
              showModal={showModal}
              isBottom={false}
            />
          </div>
        )}
        <Content>
          <div
            css={css`
              align-items: center;
              background: transparent;
              height: 100%;
              .ant-tabs-content {
                padding-top: 100px;
              }
              .ant-tabs-tab {
                font-size: 18px;
              }
              .ant-tabs-top-bar {
                position: fixed;
                right: 0;
                left: 0;
                z-index: 2;
              }
            `}
          >
            <Tabs
              defaultActiveKey="info"
              tabBarGutter={0}
              activeKey={activeTab}
              css={css`
                text-align: center;
                border-width: 0px;
                .ant-tabs-nav-container {
                  box-shadow: 5px 2px #f1f1f1;
                }
              `}
              tabBarStyle={{
                backgroundColor: "#ffffff",
              }}
              onChange={(activeKey) => tabChange(activeKey)}
            >
              <Tabs.TabPane forceRender={false} tab="Information" key="info">
                <AccountInfoTabView profileData={profileData} />
              </Tabs.TabPane>
              <Tabs.TabPane forceRender={false} tab="Accounts" key="accounts">
                <AccountListTabView
                  profileData={profileData}
                  loadingUserResponse={loadingUserResponse}
                />
              </Tabs.TabPane>

              {alertsListTabPane()}
            </Tabs>
          </div>
        </Content>
        {activeTab === "alerts" && (
          <div
            css={css`
              @media (min-width: ${breakpoints.sm}) {
                display: none;
              }
            `}
          >
            <AlertButton
              checkList={checkList}
              showDeleteConfirm={showDeleteConfirm}
              showModal={showModal}
              isBottom
            />
          </div>
        )}
      </Layout>
    </>
  );
};

export default ProfilePage;
