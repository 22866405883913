import * as React from "react";

import { Button, Icon, Menu, Modal } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  faBars,
  faHamburger,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  useAuthPopupDispatch,
  useAuthPopupState,
} from "../../../context/AuthPopupContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "@emotion/core";
import environmentVariableList from "../../../config/env";
import getActiveKeys from "./getActiveKeys";
import { useAuthInfo } from "../../../utils/customHooks";

const navItemCss = css`
  font-weight: bold !important;
  font-size: 15px;
  color: #2b2b2b;
`;
const toggleCss = css`
  background-color: transparent !important;
  color: #2b2b2b;
  font-size: 20px !important;
  padding: 0 !important;
  // width: 50px;
  height: 30px;
  box-shadow: 0 0 0 0 !important;
  margin-top: 10px;
  outline: 0;
  border: none !important;
`;
const modalCss = css`
  margin: 0 !important;
  max-width: 100% !important;
  top: 0 !important;
  z-index: 99999;
  font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
  font-weight: bold;
  width: 90% !important;
  height: 100% !important;
  @media (min-width: 576px) {
    width: 60% !important;
    left: 0 !important;
    position: absolute;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-close-x {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 16px;
    font-style: normal;
    line-height: 40px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
  }
  .ant-modal-content {
    height: 100%;
  }
  .ant-menu-item > a {
    color: #2b2b2b;
    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
    font-weight: bold !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    color: #1b51cf !important;
    background: #e4e9f6 0% 0% no-repeat padding-box;
    border-radius: 4px;
    margin: 4px !important;
  }

  .ant-menu-item-selected > a,
  .ant-menu-item-selected > a:hover {
    color: #1b51cf;
  }
  .ant-menu-submenu-title {
    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
    font-weight: bold;
    font-size: 15px;
    color: #2b2b2b;
  }
  li.ant-menu-item {
    padding-left: 24px !important;
    font-size: 15px;
    font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
    font-weight: bold;
    margin: 0 !important;
    background: white;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% - 8px) !important;
  }
  .ant-menu-inline .ant-menu-item::after {
    border-right: none !important;
  }
`;

const MobileTopLeftMenu: React.FC = () => {
  const history = useHistory();
  const dispatch = useAuthPopupDispatch();
  const { firstName, lastName, username } = useAuthPopupState();

  const { isLoggedIn } = useAuthPopupState();
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const handleClose = () => {
    // const visible = false;
    setModalVisible(false);
  };

  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  let location = useLocation();
  React.useEffect(() => {
    const { pathname } = location;
    const activeKeys = getActiveKeys(pathname);
    setSelectedKeys(activeKeys);
  }, [location.pathname]);

  const handleLogout = () => {
    setModalVisible(false);
    history.push("/");
    dispatch({
      type: "LOG_OUT",
    });
  };

  const toggleCollapsed = () => {
    if (modalVisible === false) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  };
  if (!isLoggedIn) {
    return null;
  }
  return (
    <div
      css={css`
        /* Hide in medium and large */
        padding: 0 !important;
      `}
    >
      <Button
        css={toggleCss}
        ghost={true}
        // icon="menu"
        type="primary"
        onClick={toggleCollapsed}
      >
        <FontAwesomeIcon
          icon={faBars}
          style={{
            color: "#4C4C4C",
          }}
        />
      </Button>
      <Modal
        visible={modalVisible}
        onCancel={handleClose}
        closable={true}
        footer={null}
        css={modalCss}
      >
        <Menu mode="inline" selectedKeys={selectedKeys}>
          <Menu.Item
            key="profile"
            css={css`
              height: 60px !important;
            `}
          >
            <Link
              to="/profile"
              onClick={handleClose}
              css={[
                navItemCss,
                css`
                  margin: 10px 0;
                  line-height: 1;
                }
                `,
              ]}
            >
              <span
                css={css`
                  margin: 0;
                  line-height: 1;
                  display: block;
                  font-size: 20px;
                `}
              >
                {firstName} {lastName}
              </span>
              <span
                css={css`
                  margin: 0;
                  line-height: 1;
                  font-weight: 400;
                `}
              >
                {username}
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="home">
            <Link to="/" onClick={handleClose} css={navItemCss}>
              Home
            </Link>
          </Menu.Item>

          <Menu.Item onClick={() => setModalVisible(false)} key="buy">
            <Link onClick={handleClose} css={navItemCss} to="/buy">
              Browse
            </Link>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="events">
            <Link onClick={handleClose} css={navItemCss} to="/events">
              Events
            </Link>
          </Menu.Item>
          {/* <Menu.Item onClick={() => setModalVisible(false)} key="market-place">
            <Link onClick={handleClose} css={navItemCss} to="/market-place">
              Marketplaces
            </Link>
          </Menu.Item> */}
          <Menu.Item onClick={() => setModalVisible(false)} key="watch-list">
            <Link onClick={handleClose} css={navItemCss} to="/watch-list">
              Watchlist
            </Link>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="run-list">
            <Link onClick={handleClose} css={navItemCss} to="/run-list">
              Run Lists
            </Link>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="buyer">
            <Link to="/buyer?tab=bids&page=1">Buyer</Link>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="seller">
            <Link to="/seller?tab=listed&page=1">Seller</Link>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="history">
            <Link to="/history">History</Link>
          </Menu.Item>
          <Menu.Divider />
          {/* <Menu.SubMenu key="activity" title={"Activity"}>
            <Menu.Item onClick={() => setModalVisible(false)} key="history">
              <Link to="/history">History</Link>
            </Menu.Item>
            <Menu.Item onClick={() => setModalVisible(false)} key="buyer">
              <Link to="/buyer?tab=bids&page=1">Buyer</Link>
            </Menu.Item>
            <Menu.Item onClick={() => setModalVisible(false)} key="seller">
              <Link to="/seller?tab=listed&page=1">Seller</Link>
            </Menu.Item>
          </Menu.SubMenu> */}
          <Menu.Divider />
          <Menu.Item onClick={() => setModalVisible(false)} key="contact-us">
            <a
              onClick={handleClose}
              css={navItemCss}
              rel="noopener noreferrer"
              target="_blank"
              href={environmentVariableList.LINK_CONTACT_US}
            >
              Contact Us
            </a>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="faq">
            <a
              onClick={handleClose}
              css={navItemCss}
              rel="noopener noreferrer"
              target="_blank"
              href={environmentVariableList.LINK_FAQ}
            >
              FAQ
            </a>
          </Menu.Item>
          <Menu.Item onClick={() => setModalVisible(false)} key="how-to-videos">
            <a
              onClick={handleClose}
              css={navItemCss}
              rel="noopener noreferrer"
              target="_blank"
              href={environmentVariableList.LINK_HOW_TO_VIDEOS}
            >
              How to Videos
            </a>
          </Menu.Item>
          <Menu.Item
            onClick={() => setModalVisible(false)}
            key="terms-and-conditions"
          >
            <a
              onClick={handleClose}
              css={navItemCss}
              rel="noopener noreferrer"
              target="_blank"
              href={environmentVariableList.LINK_TERMS_AND_CONDITION}
            >
              Terms and Conditions
            </a>
          </Menu.Item>
          <Menu.Item key="terms-and-condition" css={navItemCss}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={environmentVariableList.AUCTION_POLICY}
            >
              Auction Policy
            </a>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={() => setModalVisible(false)} key="6">
            <Link to="/login" onClick={handleLogout} css={navItemCss}>
              Logout
            </Link>
          </Menu.Item>
        </Menu>
      </Modal>
    </div>
  );
};

export default MobileTopLeftMenu;
