import {
  useModalFlowDispatch,
  useModalFlowState,
} from "../context/ModalContext";

import { Modal } from "antd";
import React from "react";
import { css } from "@emotion/core";

const EventInfoModal: React.FC = () => {
  const dispatch = useModalFlowDispatch();
  const state = useModalFlowState();

  const fetchEventDescription = () => {
    switch (state.eventInfo) {
      case "asIs":
        return (
          <>
            <h3>Seasoned Vehicles</h3>
            <p>
              “Seasoned Vehicles” are units that are selected by our technology
              by analyzing factors such as year, price range, mileage,
              condition, etc. to place them in the appropriate event.
            </p>
            <ul>
              <li>
                Seasoned Vehicles should be checked by your shop as some may
                require typical reconditioning and/or detailing to be frontline
                ready.
              </li>
              <br />
              <li>
                All Seasoned Vehicles are sold “as-is”, however, with your
                satisfaction as our top priority, you can return any Seasoned
                Vehicle for any reason for a $500 fee, with no shipping charges.
              </li>
              <br />
              <li>
                Buyers should review all listing photos carefully and expect
                Seasoned Vehicles may have some rust, oxidation, or damage
                (appropriate for the age & mileage). TMU/Title issues will be
                announced.
              </li>
            </ul>
          </>
        );

      case "hyperlane":
        return (
          <>
            <h3>Hyperlane Vehicles</h3>
            <p style={{ fontWeight: "bold" }}>
              Hyperlane Vehicles are available for a very limited time and need
              to be sold FAST!
            </p>

            <p>
              All Hyperlane Vehicles come with RumbleOn’s Limited Guarantee.
              This means they conform to the same quality standards and policies
              as the units in our Tuesday “RMBL” auctions.
            </p>

            <p>
              The RumbleOn Limited Guarantee covers the three (3) items listed
              below:
            </p>
            <ul>
              <li>The vehicle will come with a good, clear title.</li>
              <br />
              <li>The mileage is actual.</li>
              <br />
              <li>
                The vehicle was not grossly misrepresented in the announcements,
                description, or photos.
              </li>
              <br />
              <li>
                We disclose all pertinent information we are aware of at the
                time of sale, however, bidders and buyers should be aware we
                have not performed any kind of inspection, and buyers should
                assume there could be other conditions that exist.
              </li>
            </ul>
          </>
        );

      default:
        break;
    }
  };
  return (
    <div>
      <Modal
        css={css`
          font-family: aktiv-grotesk;
          @media (max-width: 768px) {
            height: 80vh;
            margin: 0;
            top: 0;
            max-width: 100%;
            .ant-modal-body {
              height: 100vh !important;
              overflow: hidden;
            }
          }
        `}
        style={{}}
        visible={state.showEventInfo}
        footer={null}
        closable
        onCancel={() =>
          dispatch({
            type: "HIDE_REQUEST_INFO_MODAL",
            args: {
              showEventInfo: false,
              eventInfo: "",
            },
          })
        }
      >
        {fetchEventDescription()}
      </Modal>
    </div>
  );
};

export default EventInfoModal;
