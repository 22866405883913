import { Button, Col, Modal, Row, notification } from "antd";
import {
  useAuthPopupDispatch,
  useAuthPopupState,
} from "../context/AuthPopupContext";
import {
  useModalFlowDispatch,
  useModalFlowState,
} from "../context/ModalContext";

import { Mutation } from "../generated/graphql";
import React from "react";
import { css } from "@emotion/core";
import { loader } from "graphql.macro";
import { useMutation } from "react-apollo";

const ACCEPT_TOU_MUTATION = loader("./graphql/updateTOUForLegacyUsers.graphql");

const AcceptTOU: React.FC = () => {
  const authDispatch = useAuthPopupDispatch();
  const dispatch = useModalFlowDispatch();
  const Uuid = "temp-uuid";
  const state = useModalFlowState();
  const { showAcceptTOUModal } = state;

  const [acceptTOU, { loading }] = useMutation<Mutation>(ACCEPT_TOU_MUTATION);

  const handleAccept = () => {
    try {
      // acceptTOU({
      //   variables: {
      //     UserUuid: Uuid,
      //     HasAcceptedV4TnC: true,
      //   },
      // });

      dispatch({
        type: "HIDE_ACCEPT_TOU",
        args: {
          showAcceptTOUModal: false,
        },
      });

      dispatch({
        type: "SHOW_ACCEPT_FINANCE_OPT_IN_LANGUAGE",
        args: {
          showAcceptFinanceOptInModal: true,
        },
      });
    } catch (error) {
      authDispatch({ type: "LOG_OUT" });
      notification.error({
        message:
          "You have been logged out. In order to use this site, you must accept our Terms of Use and Privacy Policy",
      });
    }
  };

  const handleDecline = () => {
    notification.error({
      message:
        "You have been logged out. In order to use this site, you must accept our Terms of Use and Privacy Policy",
    });
    authDispatch({ type: "LOG_OUT" });
    dispatch({
      type: "HIDE_ACCEPT_TOU",
      args: {
        showAcceptTOUModal: false,
      },
    });
  };

  return (
    <div>
      <Modal
        visible={showAcceptTOUModal}
        closable
        footer={null}
        css={css`
          width: 800px !important;
          height: 500px;
          .ant-modal-close-x {
            display: none;
          }
          @media (max-width: 990px) {
          }
        `}
        onCancel={() =>
          dispatch({
            type: "HIDE_ACCEPT_OFFER_SUCCESS_MODAL",
            args: {
              showAcceptTOUModal: false,
            },
          })
        }
      >
        <Row
          type="flex"
          justify="end"
          // align="middle"
          gutter={8}
          css={css`
            height: 60px;
            text-align: right;
            padding-top: 4%;
            @media (max-width: 7680px) {
              text-align: center;
              height: 80px;
            }
          `}
        >
          <Col xs={24} lg={6}>
            <Button
              block
              type="default"
              onClick={handleDecline}
              css={css`
                width: 100%;
                border: 1px solid gray;
                @media (max-width: 990px) {
                }
              `}
            >
              Decline
            </Button>
          </Col>

          <Col xs={24} lg={6}>
            <Button
              block
              loading={loading}
              type="primary"
              onClick={handleAccept}
              css={css`
                @media (max-width: 768px) {
                  margin-top: 2%;
                  width: 100%;
                }
              `}
            >
              Accept
            </Button>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AcceptTOU;
