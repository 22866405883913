
interface GetVehicleDetailUrlType {
    itemId: string | number; 
    year?: string | number; 
    make?: string; 
    model?: string; 
}

function getRedirectUrl  () {
    if(window.location){
        if(window.location.pathname === '/buy' && window.location.search != ''){
            return `?redirect=${window.location.pathname}${window.location.search}`;
        }
    }
    return '';
}

export default function getVehicleDetailUrl ({itemId,year, make, model }: GetVehicleDetailUrlType){
    const redirectUrl = getRedirectUrl();
    if(year || make || model){
        const items =  [ year, make, model].filter(item => item !== undefined);
        const listingName = items.join('-').split(" ").join("-").split('/').join('');
        console.log()
        if(window)
        return `/item/${itemId}/${listingName}${redirectUrl}`;
    } 
    return `/item/${itemId}${redirectUrl}`;
    
}