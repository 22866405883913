import * as React from "react";

import { ApolloClient, InMemoryCache } from "apollo-boost";
import { ApolloLink, concat } from "apollo-link";
import {
  useAuthPopupDispatch,
  useAuthPopupState,
} from "../../context/AuthPopupContext";

import { ApolloProvider } from "react-apollo";
import _ from "lodash";
import { createHttpLink } from "apollo-link-http";
import environmentVariableList from "../../config/env";
import getRefreshToken from "../../api/getRefreshToken";
import { useHistory } from "react-router-dom";

interface Props {
  children: React.ReactChildren | React.ReactElement;
}

const ApolloWrapper: React.FC<Props> = ({ children }) => {
  const dispatch = useAuthPopupDispatch();
  const { expiresOn } = useAuthPopupState();
  const { refreshToken, apiKey } = useAuthPopupState();
  const history = useHistory();

  const apolloClient = () => {
    const cache = new InMemoryCache();
    const link = createHttpLink({
      uri: environmentVariableList.GRAPHQL_ENDPOINT,
    });
    const authMiddleWare = new ApolloLink((operation, forward) => {
      if (expiresOn) {
        const expTime = new Date(expiresOn);
        const currTime = new Date();
        if (
          expTime.getTime() < currTime.getTime() - 10000 &&
          refreshToken &&
          apiKey
        ) {
          getRefreshToken(refreshToken, apiKey).then((response) => {
            if (response) {
              console.log(response);
              dispatch({
                type: "POST_LOGIN_SUCCESS",
                args: response,
              });
            } else {
              history.push("/");
              dispatch({
                type: "LOG_OUT",
              });
            }
          });
        }
      }
      return forward(operation);
    });
    const client = new ApolloClient({
      cache,
      link: concat(authMiddleWare, link),
    });
    return client;
  };
  return <ApolloProvider client={apolloClient()}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
