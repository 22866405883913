import getTimeWithZone, { getTimeWithLocalTimeZone } from "./getTimeWithZone";
import { useEffect, useRef, useState } from "react";

import { BidAndBuyDataType } from "../components/VehicleDetail/VehicleDetailsDataTypes";
import environmentVariableList from "../config/env";
import { listingSignalRClient } from "../App";
import { useAuthPopupState } from "../context/AuthPopupContext";

const MAX_REMAINING_TIME_TO_SHOW_LABEL_MS = 24 * 3600 * 1000 - 1; // 1 day
const MAX_REMAINING_TIME_TO_CONSIDER_MS = 2 * 24 * 3600 * 1000; // 2 days - performance improvement

export function useDebounce<T extends string | number>(
  value: T,
  delay: number
) {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function useAuthInfo<T>(): {
  apiKey: string | null;
  mpId: number | null;
  accountId: number | null;
  userId: string | null;

  accountList: {
    accountId: number;
    accountName: string;
    accountNumber: string;
    userId: string | number;
    enableSelling: boolean;
    badgeNumber: string;
    city: string;
    state: string;
    paymentTypesArray: [];
    defaultPaymentType: string;
    enableBidding: boolean;
    isPrimaryAccount: boolean;
  }[];
} {
  const { apiKey, mpId, accountId, userId, accountList } = useAuthPopupState();

  return {
    apiKey: apiKey || null,
    mpId: mpId || null,
    accountId: accountId || null,
    userId: userId || null,
    accountList: accountList || [],
  };
}

export function useProfileInfo<T>(): {
  apiKey: string | null;
  mpId: number | null;
  accountId: number | null;
  userId: string | null;

  accountList: {
    accountId: number;
    accountName: string;
    accountNumber: string;
    userId: string | number;
    enableSelling: boolean;
    badgeNumber: string;
    city: string;
    state: string;
    paymentTypesArray: [];
    defaultPaymentType: string;
    enableBidding: boolean;
    isPrimaryAccount: boolean;
  }[];
} {
  const { apiKey, mpId, accountId, userId, accountList } = useAuthPopupState();

  return {
    apiKey: apiKey || null,
    mpId: mpId || null,
    accountId: accountId || null,
    userId: userId || null,
    accountList: accountList || [],
  };
}

export const useListingSignalR = (
  listing: { id: number; [propName: string]: any } | null | undefined
) => {
  const [updatedItem, setUpdatedItem] = useState<any>(null);

  useEffect(() => {
    let ignore = false;

    setUpdatedItem(null);

    if (listing?.id || listing?.id === 0) {
      const subscriberId = listingSignalRClient.subscribeForUpdates(
        listing?.id,
        () => {
          fetch(`${environmentVariableList.LISTING_ELASTIC_URL}/_msearch`, {
            method: "POST",
            headers: {
              "Content-Type": "application/x-ndjson",
              Accept: "application/json",
              Authorization: `Basic ${btoa(
                environmentVariableList.LISTING_ELASTIC_CREDENTIAL
              )}`,
            },
            body: `{"preference":"page"}\n{"query":{"terms":{"id":[${listing.id}]}},"size":1}\n`,
          })
            .then((response) => {
              return response.json();
            })
            .then((responseJson) => {
              if (
                responseJson?.responses &&
                responseJson?.responses?.length > 0
              ) {
                const firstResponse = responseJson.responses[0];
                if (
                  !ignore &&
                  firstResponse?.hits?.hits &&
                  firstResponse?.hits?.hits?.length > 0
                ) {
                  const hits = firstResponse?.hits?.hits;
                  const latestData = hits
                    .filter((hit: any) => !!hit?._source)
                    .map((hit: any) => hit._source);
                  if (latestData && latestData.length > 0) {
                    console.log("elastic signal-r update", latestData[0]);
                    setUpdatedItem(latestData[0]);
                  }
                }
              }
            })
            .catch((error) =>
              console.warn("Listing update failed with:", error)
            );
        }
      );
      return () => {
        ignore = true;
        listingSignalRClient.unsubscribeFromUpdate(subscriberId);
      };
    }
  }, [listing]);
  return updatedItem;
};

interface UseTimeLeftForBiddingI {
  timeLeftDisplayValue: string;
  shouldDisplayTimeLeft: boolean;
  timeLeft: number;
  maxTime: number;
}
export const useTimeLeftForBidding = (
  endDate: string,
  hideColon?: boolean
): UseTimeLeftForBiddingI => {
  const [timeLeft, setTimeLeft] = useState<number>(
    MAX_REMAINING_TIME_TO_SHOW_LABEL_MS + 1
  );

  useEffect(() => {
    if (endDate) {
      const remainingTimeMS =
        new Date(getTimeWithLocalTimeZone(endDate)).getTime() -
        new Date().getTime();
      if (
        remainingTimeMS <= MAX_REMAINING_TIME_TO_CONSIDER_MS &&
        remainingTimeMS >= 1000
      ) {
        const interval = setInterval(() => {
          setTimeLeft(
            new Date(getTimeWithLocalTimeZone(endDate)).getTime() -
              new Date().getTime()
          );
        }, 250);
        return () => clearInterval(interval);
      }
    }
  }, [endDate]);

  let display: string = "";

  if (timeLeft <= MAX_REMAINING_TIME_TO_SHOW_LABEL_MS && timeLeft >= 1000) {
    const remainingTimeSec = Math.ceil(timeLeft / 1000);
    const minutes = Math.floor(remainingTimeSec / 60);
    const sec = remainingTimeSec % 60;

    if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      display = hideColon
        ? `${hours}H ${
            remainingMinutes > 9 ? remainingMinutes : "0" + remainingMinutes
          }M`
        : `${hours}H:${
            remainingMinutes > 9 ? remainingMinutes : "0" + remainingMinutes
          }M`;
    } else if (minutes >= 15) {
      display = `${minutes}M`;
    } else {
      display = hideColon
        ? `${minutes}M ${sec > 9 ? sec : "0" + sec}S`
        : `${minutes}M:${sec > 9 ? sec : "0" + sec}S`;
    }
  }

  return {
    timeLeftDisplayValue: display,
    shouldDisplayTimeLeft: !!display,
    timeLeft: timeLeft,
    maxTime: MAX_REMAINING_TIME_TO_SHOW_LABEL_MS,
  };
};

export const preBidEnabled = (bidBuyDetails: BidAndBuyDataType) => {
  if (
    String(bidBuyDetails.mpFeatures).split(",").includes("492") &&
    !String(bidBuyDetails.eventFeatures).split(",").includes("39")
  ) {
    return true;
  }

  return false;
};
