import { getTimeWithLocalTimeZone, getTimeWithZone } from "./getTimeWithZone";

import moment from "moment";
import momentTZ from "moment-timezone";

export const formatNumber = (value: number) =>
  new Intl.NumberFormat("en-US").format(value);

export const formatCurrency = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(value);

export const getFormattedDateOnly = (value: string): string => {
  const parsedDate = new Date(getTimeWithZone(value));
  return parsedDate.toLocaleDateString("en-US");
};

export const getFormattedDateTime = (value: string): string => {
  const parsedDate = new Date(getTimeWithZone(value));
  return parsedDate.toLocaleString("en-US");
};

export const getFormattedLocalDateTime = (value: string): string => {
  const parsedDate = new Date(getTimeWithLocalTimeZone(value));
  return parsedDate.toLocaleString("en-US");
};

export const stripHtml = (text: string) => {
  if (text !== "" && text) {
    return text.replace(/<(?:.|\n)*?>/gm, "");
  }
};

interface GetLimitedTextI {
  text: string;
  maxLength: number;
  showAll: boolean;
}
export const getLimitedText = ({
  text,
  maxLength,
  showAll,
}: GetLimitedTextI) => {
  if (text.length > maxLength && !showAll) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export const formatPhoneNumber = (phone: string) => {
  //Filter only numbers from the input
  let cleaned = ("" + phone).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  return null;
};

export const makeTimeText = (timeA: number, timeB: number, maxValues = 6) => {
  // const duration = momentTZ.duration(864123456, 'milliseconds');
  const duration = momentTZ.duration(timeB - timeA, "milliseconds");

  const timeBlock = {
    yrs: duration.years(),
    mo: duration.months(),
    days: duration.days(),
    hrs: duration.hours(),
    min: duration.minutes(),
    sec: duration.seconds(),
  };

  return Object.entries(timeBlock)
    .filter((entry) => {
      // filters out zero values except for seconds
      const [label, time] = entry;
      return time > 0 || label === "sec";
    })

    .slice(0, maxValues) // default is all the non-zeros
    .map((entry, index, arr) => {
      let [label, val] = entry;

      // make sigular if value is 1
      if (val === 1 && ["yrs", "days", "hrs"].includes(label)) {
        label = label.slice(0, -1);
      }

      return `${val} ${label}`;
    })
    .join(" ");
};

export const formatDate = (value: string) => {
  const a = moment();
  const b = moment(value);
  const expiredDateH = b.diff(a, "hours");
  const expiredDateD = b.diff(a, "days");
  const expiredDateM = b.diff(a, "months");
  const expiredDateY = b.diff(a, "years");
  const expiredDate = b.diff(a, "hours");
  // return parsedDate.toLocaleDateString("en-US");
  return expiredDate < 0
    ? expiredDateH < -24
      ? expiredDateD < -30
        ? expiredDateM < -1 && expiredDateM < -12
          ? expiredDateY > -1
            ? `expired ${Math.abs(expiredDateY)} years ago`
            : `expired ${Math.abs(expiredDateY)} year ago`
          : expiredDateM === -1
          ? `expired ${Math.abs(expiredDateM)} month ago`
          : `expired ${Math.abs(expiredDateM)} months ago`
        : expiredDateD === -1
        ? `expired ${Math.abs(expiredDateD)} day ago`
        : `expired ${Math.abs(expiredDateD)} days ago`
      : expiredDateH === -1
      ? ` expired ${Math.abs(expiredDateH)} hour ago`
      : ` expired ${Math.abs(expiredDateH)} hours ago`
    : `expires ${b.fromNow()}`;
};
// `${Math.abs(expiredDateD)} hours ago`
// `${Math.abs(expiredDateH)} hours ago`

export const formatDateForBidHistory = (value: string) => {
  const a = momentTZ.tz(momentTZ.tz.guess());
  const b = momentTZ.tz(value, momentTZ.tz.guess());
  const expiredDateH = b.diff(a, "hours");
  const expiredDateD = b.diff(a, "days");
  const expiredDateM = b.diff(a, "months");
  const expiredDateY = b.diff(a, "years");
  const expiredDate = b.diff(a, "hours");
  // return parsedDate.toLocaleDateString("en-US");
  return expiredDate < 0
    ? expiredDateH < -24
      ? expiredDateD < -30
        ? expiredDateM < -1 && expiredDateM < -12
          ? expiredDateY > -1
            ? `${Math.abs(expiredDateY)} years ago`
            : `${Math.abs(expiredDateY)} year ago`
          : expiredDateM === -1
          ? `${Math.abs(expiredDateM)} month ago`
          : `${Math.abs(expiredDateM)} months ago`
        : expiredDateD === -1
        ? `${Math.abs(expiredDateD)} day ago`
        : `${Math.abs(expiredDateD)} days ago`
      : expiredDateH === -1
      ? ` ${Math.abs(expiredDateH)} hour ago`
      : ` ${Math.abs(expiredDateH)} hours ago`
    : `${b.fromNow()}`;
};

export const statusFormatter = (value: number) => {
  switch (value) {
    case 1:
      return "Incomplete";
    case 2:
      return "In Review";
    case 3:
      return "Approved";
    case 4:
      return "Sold";
    case 5:
      return "Denied";
    case 6:
      return "Deleted";
    case 7:
      return "Removed";
    case 8:
      return "Accepted";
    case 9:
      return "Published";
    case 10:
      return "Expired";
    case 11:
      return "In Review";
    case 12:
      return "Edited";
    default:
      return "";
  }
};

export const tokenFormatter = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const formatMileage = (value: number) =>
  value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");

export function shuffleArray<T>(array: Array<T>): Array<T> {
  const newArray = array.slice();
  for (let i = newArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

export const addCommas = (num: any = "") =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const removeNonNumeric = (num: any = "") =>
  num.toString().replace(/[^0-9]/g, "");

export function daysRemaining(date: string) {
  var eventDate = moment(getTimeWithLocalTimeZone(date));
  var todaysDate = moment();
  return eventDate.diff(todaysDate, "minutes");
}

export function convertMinutes(num: number) {
  let d = Math.floor(num / 1440); // 60*24
  let h = Math.floor((num - d * 1440) / 60);
  let m = Math.round(num % 60);

  if (d > 0) {
    return d + "D " + h + "H " + m + "M";
  } else if (h > 0) {
    return h + "H " + m + "M";
  } else if (m > 0) {
    return m + "M";
  }
  return "-";
}
