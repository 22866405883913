import {
  useModalFlowDispatch,
  useModalFlowState,
} from "../context/ModalContext";

import { Modal } from "antd";
import React from "react";
import ReactInfoForm from "./components/RequestInfoForm";
import { css } from "@emotion/core";

const RequestInfoModal: React.FC = () => {
  const dispatch = useModalFlowDispatch();
  const state = useModalFlowState();
  return (
    <div>
      <Modal
        css={css`
          @media (max-width: 768px) {
            height: 80vh;
            margin: 0;
            top: 0;
            max-width: 100%;
            .ant-modal-body {
              height: 100vh !important;
              overflow: hidden;
            }
          }
        `}
        style={{}}
        visible={state.showRequestInfoModal}
        footer={null}
        closable
        onCancel={() =>
          dispatch({
            type: "HIDE_REQUEST_INFO_MODAL",
            args: {
              showRequestInfoModal: false,
            },
          })
        }
      >
        <ReactInfoForm />
      </Modal>
    </div>
  );
};

export default RequestInfoModal;
