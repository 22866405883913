import {
  AcceptOfferSuccessModal,
  CashOfferVideoModal,
  EventInfoModal,
  FinanceNonTCIStateModal,
  RequestInfoModal,
} from "./Modals";
import { Global, css } from "@emotion/core";
import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import AcceptFinanceOptInLanguage from "./Modals/AcceptFinanceOptInLanguage";
import AcceptTOU from "./Modals/AcceptTOU";
import ApolloWrapper from "./components/ApolloWrapper/ApolloWrapper";
import { AuthPopupContextProvider } from "./context/AuthPopupContext";
import { CashOfferContextProvider } from "./context/CashOfferContext";
import { DetailsFlowContextProvider } from "./context/DetailsFlowContext";
import { GlobalFlowContextProvider } from "./context/Global";
import { HomeContextProvider } from "./context/HomeContext";
import Layout from "./components/Layout/Layout";
import { ModalFlowContextProvider } from "./context/ModalContext";
import { NotificationContextProvider } from "./context/NotificationContext";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { SellFlowContextProvider } from "./context/SellFlowContext";
import SignalRClient from "./SignalRClient";
import { Spin } from "antd";
import { WatchListContextProvider } from "./context/WatchListContext";
import environmentVariableList from "./config/env";

const AsyncHomePage = lazy(() => import("./components/Home/Home"));
const AsyncLogin = lazy(() => import("./components/Login/Login"));
const AsyncInternalLogin = lazy(() =>
  import("./components/Login/InternalLogin")
);

const AsyncBuyPage = lazy(() => import("./components/BuyPage/BuyPage"));
const AsyncEventsPage = lazy(() =>
  import("./components/EventsPage/EventsPage")
);
const AsyncRunListPage = lazy(() =>
  import("./components/RunList/RunListUpdated")
);
const AsyncMarketplacesPage = lazy(() =>
  import("./components/Marketplaces/MarketplacesPage")
);
const AsyncRecentlyViewedPage = lazy(() =>
  import("./components/RecentlyViewed/RecentlyViewedPage")
);
const AsyncWatchlistPage = lazy(() =>
  import("./components/WatchList/WatchListPage")
);
const AsyncItemsBiddingOnPage = lazy(() =>
  import("./components/ItemsBiddingOn/ItemsBiddingOnPage")
);
const AsyncProfilePage = lazy(() => import("./components/Profile/ProfilePage"));

const AsyncLogInPopUp = lazy(() =>
  import("./components/LoginPopUp/LoginPopUp")
);

const AsyncDetailContainer = lazy(() =>
  import("./components/VehicleDetail/VehicleDetail")
);

const AsyncBuyerActivity = lazy(() =>
  import("./components/Activity/buyerActivity/BuyerActivityUpdated")
);
const AsyncHistoryActivity = lazy(() =>
  import("./components/Activity/historyActivity/HistoryActivity")
);

const AsyncSellerActivity = lazy(() =>
  import("./components/Activity/sellerActivity/SellerActivity")
);

const globalStyle = css`
  body,
  li,
  p {
    font-family: "aktiv-grotesk", Arial, Helvetica, sans-serif !important;
  }
`;

export const listingSignalRClient = new SignalRClient(
  `${environmentVariableList.SIGNALR_URL}/ListingHub`,
  "ItemInfoUpdate",
  "ItemInfoSubscribe"
);
listingSignalRClient
  .connect()
  .then((id) => console.log("cponnected", id))
  .catch((error) =>
    console.warn("SignalR ListingHub connection initialization failed:", error)
  );

export const watchListSignalRClient = new SignalRClient(
  `${environmentVariableList.SIGNALR_URL}/WatchListItemHub`,
  "WatchListItemInfoUpdate",
  "WatchListItemInfoSubscribe"
);
watchListSignalRClient
  .connect()
  .then()
  .catch((error) =>
    console.warn(
      "SignalR WatchListItemHub connection initialization failed:",
      error
    )
  );

export const notificationSignalRClient = new SignalRClient(
  `${environmentVariableList.SIGNALR_URL}/NotificationItemHub`,
  "NotificationItemInfoUpdate",
  "NotificationItemInfoSubscribe"
);
notificationSignalRClient
  .connect()
  .then()
  .catch((error) =>
    console.warn(
      "SignalR NotificationItemHub connection initialization failed:",
      error
    )
  );

const App: React.FC = () => {
  return (
    <AuthPopupContextProvider>
      <GlobalFlowContextProvider>
        <ModalFlowContextProvider>
          <SellFlowContextProvider>
            <DetailsFlowContextProvider>
              <WatchListContextProvider>
                <CashOfferContextProvider>
                  <NotificationContextProvider>
                    <HomeContextProvider>
                      <ApolloWrapper>
                        <Layout>
                          <Global styles={globalStyle} />
                          <AcceptOfferSuccessModal />
                          <AcceptTOU />
                          <AcceptFinanceOptInLanguage />
                          <FinanceNonTCIStateModal />
                          <EventInfoModal />
                          <RequestInfoModal />
                          <CashOfferVideoModal />
                          <Suspense
                            fallback={
                              <div
                                css={css`
                                  text-align: center;
                                  padding-top: 35vh;
                                  min-height: 75vh;
                                  min-width: 80vw;
                                `}
                              >
                                <Spin spinning />
                              </div>
                            }
                          >
                            <ScrollToTop />
                            <Switch>
                              <Route path="/" exact component={AsyncHomePage} />
                              <Route
                                exact
                                path="/buyer"
                                component={AsyncBuyerActivity}
                              />
                              <Route
                                exact
                                path="/seller"
                                component={AsyncSellerActivity}
                              />
                              <Route
                                exact
                                path="/buy"
                                component={AsyncBuyPage}
                              />
                              <Route
                                exact
                                path="/events"
                                component={AsyncEventsPage}
                              />
                              <Route
                                exact
                                path="/history"
                                component={AsyncHistoryActivity}
                              />
                              <Route
                                exact
                                path="/run-list"
                                component={AsyncRunListPage}
                              />
                              <Route
                                exact
                                path="/watch-list"
                                component={AsyncWatchlistPage}
                              />
                              <Route
                                exact
                                path="/items-bidding"
                                component={AsyncItemsBiddingOnPage}
                              />
                              <Route
                                exact
                                path="/recently-viewed"
                                component={AsyncRecentlyViewedPage}
                              />
                              <Route
                                exact
                                path="/market-place"
                                component={AsyncMarketplacesPage}
                              />
                              <Route
                                exact
                                path="/profile"
                                component={AsyncProfilePage}
                              />
                              <Route
                                exact
                                path="/item/:listingId/:listingName?/:typeId?"
                                component={AsyncDetailContainer}
                              />

                              <Route path="/login" component={AsyncLogin} />
                              <Route
                                path="/internal-login"
                                component={AsyncInternalLogin}
                              />
                              <Route component={AsyncHomePage} />
                            </Switch>
                            <AsyncLogInPopUp />
                          </Suspense>
                        </Layout>
                      </ApolloWrapper>
                    </HomeContextProvider>
                  </NotificationContextProvider>
                </CashOfferContextProvider>
              </WatchListContextProvider>
            </DetailsFlowContextProvider>
          </SellFlowContextProvider>
        </ModalFlowContextProvider>
      </GlobalFlowContextProvider>
    </AuthPopupContextProvider>
  );
};

export default App;
