import * as React from "react";

import { useHomeDispatch, useHomeState } from "../../../context/HomeContext";

import { useAuthPopupState } from "../../../context/AuthPopupContext";
import { useLocation } from "react-router-dom";

function useRouteTracking() {
  const [stackCount, setStackCount] = React.useState(0);
  const { hasRouterBack } = useHomeState();
  const { isLoggedIn } = useAuthPopupState();

  const homeDispatch = useHomeDispatch();
  let location = useLocation();

  React.useEffect(() => {
    const newCount = stackCount + 1;
    setStackCount(newCount);
    if (!isLoggedIn) {
      setStackCount(0);
    } else if (newCount > 1 && !hasRouterBack) {
      homeDispatch({ type: "SET_ROUTER_BACK", args: true });
    }
  }, [location]);
}
export default useRouteTracking;
