/* eslint-disable */

import * as React from "react";

type Action =
  | { type: "POST_LOGIN_SUCCESS"; args: any }
  | { type: "LOG_OUT" }
  | { type: "SOFT_LOGOUT" }
  | { type: "SHOW_LOGIN" }
  | { type: "HIDE_AUTH_POPUP" };

type Dispatch = (action: Action) => void;
type State = {
  loginVisible: boolean;
  signupVisible: boolean;
  isLoggedIn?: boolean;
  isModerator: boolean;
  IsLegacyUser?: boolean;
  UserNotificationPreferences?: string;
  apiKey?: string;
  refreshToken?: string;
  firstName?: string;
  lastName?: string;
  mpId?: number;
  expiresOn?: string;
  accountId?: number;
  userId?: string;
  username?: string;
  enableSelling?: boolean;
  accountList?: {
    accountId: number;
    accountName: string;
    accountNumber: string;
    zipcode: string;
    userId: string | number;
    enableSelling: boolean;
  }[];
};
type AuthPopupProviderProps = {
  children: React.ReactNode;
};

const AuthPopupStateContext = React.createContext<State>({
  loginVisible: false,
  signupVisible: false,
  IsLegacyUser: false,
  isLoggedIn: false,
  isModerator: false,
  UserNotificationPreferences: "1",
  enableSelling: false,
});

const AuthPopupDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const localStorageKey = "AUTH_LOCAL_STORAGE_KEY";
let localAuthState = {
  loginVisible: false,
  signupVisible: false,
  isLoggedIn: false,
  isModerator: false,
  apiKey: undefined,
  mpId: undefined,
  accountId: undefined,
  userId: undefined,
  accountList: [],
  enableSelling: false,
};
try {
  localAuthState = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
} catch (error) {
  console.error("Parse local state failed");
}
const AuthPopupReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SHOW_LOGIN":
      return {
        ...state,
        loginVisible: true,
        signupVisible: false,
      };
    case "HIDE_AUTH_POPUP":
      return {
        ...state,
        loginVisible: false,
        signupVisible: false,
      };
    case "POST_LOGIN_SUCCESS": {
      const localStoreData = {
        apiKey: action.args.apiKey,
        expiresOn: action.args.expiresOn,
        refreshToken: action.args.refreshToken,
        mpId: action.args.mpId,
        accountId: action.args.user.accountId,
        userId: action.args.user.userId,
        username: action.args.user.username,
        firstName: action.args.user.firstName,
        lastName: action.args.user.lastName,
        accountList: action.args.user.accountList || [],
        enableSelling: action.args.user.enableSelling,
      };
      localStorage.setItem(localStorageKey, JSON.stringify(localStoreData));
      return {
        ...state,
        signupVisible: false,
        loginVisible: false,
        isLoggedIn: true,
        accountNumber: action.args.user.accountNumber,
        marketplaceId: action.args.user.marketplaceId,
        ...localStoreData,
      };
    }
    case "LOG_OUT":
      return {
        signupVisible: false,
        loginVisible: false,
        isLoggedIn: false,
        isModerator: false,
      };
    default:
      return state;
  }
};

const AuthPopupContextProvider = ({ children }: AuthPopupProviderProps) => {
  const [state, dispatch] = React.useReducer(AuthPopupReducer, localAuthState);
  React.useEffect(() => {
    localStorage.setItem(
      localStorageKey,
      JSON.stringify({
        ...state,
        loginVisible: false,
        signupVisible: false,
      })
    );
  });
  return (
    <AuthPopupStateContext.Provider value={state}>
      <AuthPopupDispatchContext.Provider value={dispatch}>
        {children}
      </AuthPopupDispatchContext.Provider>
    </AuthPopupStateContext.Provider>
  );
};
const useAuthPopupState = () => {
  const context = React.useContext(AuthPopupStateContext);

  if (context === undefined) {
    throw new Error(
      "useAuthPopupContext must be used within a AuthPopupContextProvider"
    );
  }
  const storedData = localStorage.getItem(localStorageKey);
  const updatedStorage = storedData ? JSON.parse(storedData) : {};
  return { ...context, ...updatedStorage };
};

const useAuthPopupDispatch = () => {
  const context = React.useContext(AuthPopupDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useAuthPopupDispatch must be used within a AuthPopupContextProvider"
    );
  }
  return context;
};

export { AuthPopupContextProvider, useAuthPopupState, useAuthPopupDispatch };
