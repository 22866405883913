import env from "../config/env";
import momentTz from "moment-timezone";
export const getTimeWithZone = (value: string) => {
  const result = momentTz.tz(value, env.DEFAULT_TIME_ZONE).toDate();
  return result;
};

export const getTimeWithLocalTimeZone = (value: string) => {
  const timezone = momentTz.tz.guess();
  const result = momentTz.tz(value, timezone).toDate();
  return result;
};

export default getTimeWithZone;
