import * as React from "react";

import { Alert, Icon, Layout } from "antd";
import { Link, Redirect, useLocation } from "react-router-dom";
import getActiveKeys, { getPageTitle } from "./components/getActiveKeys";

import BidderCount from "./components/BidderCount";
import Footer from "./components/Footer";
import MainMenu from "./components/MainMenu";
import MobileNotification from "./components/MobileNotification";
import MobileTopLeftMenu from "./components/MobileTopLeftMenu";
import { TopBanner } from "../UI/TopBanner";
import TopRightMenu from "./components/TopRightMenu";
import _ from "lodash";
import { breakpoints } from "../../constants";
import { css } from "@emotion/core";
import { loader } from "graphql.macro";
import { useAuthPopupState } from "../../context/AuthPopupContext";
import { useHomeDispatch } from "../../context/HomeContext";
import { useLazyQuery } from "react-apollo";
import useRouteTracking from "./components/useRouteTracking";
import { useWatchListDispatch } from "../../context/WatchListContext";

const WATCH_LIST_ITEMS = loader("./watchListItemsResponse.graphql");
const GET_USER_COUNT = loader("./getLoggedInUsersCountResponse.graphql");
const GET_USER_PROFILE = loader("./userProfile.graphql");

const { Header, Content } = Layout;
const layoutCss = css`
  width: 100%;
  margin: 0px auto;
  background: #whitesmoke;
  .ant-layout-content {
    background: whitesmoke !important;
  }
  .ant-layout-header {
    height: 66px;
  }
`;
const logoCss = css`
  /* Mobile style */
  background-repeat: no-repeat;
  background-position: 100%;
  height: 66px;
  margin-left: 12px;
  float: left;
  margin-right: 12px;
  @media (max-width: ${breakpoints.md}) {
    max-width: 190px;
    text-align: center;
  }
  /* Desktop style */
  @media (min-width: ${breakpoints.md}) {
    max-width: 219px;
  }
`;

const logoLinkCss = css`
  padding-left: 15px;
`;
const headerCss = css`
  background: white;
  display: flex;
  box-shadow: 0px 1px 2px lightgray;
  padding: 5px !important;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 66px;
  z-index: 100;
  @media (max-width: 1103px) {
    display: none;
  }
  @media print {
    display: none;
  }
`;
const headerMobileCss = css`
  background: white;
  padding: 0 16px !important;
  padding-left: 25px !important;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  border-bottom: 1px solid #e6e6e6;
  @media (min-width: 1104px) {
    display: none;
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0 !important;
  }
  @media (max-width: 294px) {
    padding: 2% !important;
  }
  @media print {
    display: none;
  }
`;

const logoMobileLinkCss = css`
  overflow: hidden;
  padding-left: 15px;
  @media (max-width: 294px) {
    padding-left: 4px !important;
  }
`;

const contentCss = css`
  padding: 0;
  @media print {
    padding: 0;
  }
`;
interface Props {
  children: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const { pathname, search } = useLocation();
  const { isLoggedIn, apiKey, mpId } = useAuthPopupState();
  const dispatch = useWatchListDispatch();
  const homeDispatch = useHomeDispatch();
  useRouteTracking();
  const [bidderCount, setBidderCount] = React.useState(0);

  const [getWishListItems] = useLazyQuery(WATCH_LIST_ITEMS, {
    variables: {
      apiKey,
      mpId,
    },
    fetchPolicy: "network-only",
    pollInterval: isLoggedIn ? 20000 : 0,
    onCompleted: async (result) => {
      const items = _.get(result, "watchListItemsResponse.items", []);
      if (items.length) {
        const watchListItemsIds = items.map((item: any) => item.itemId);
        dispatch({ type: "SET_WATCH_LIST_ITEMS", args: watchListItemsIds });
      }
    },
  });

  const [getUserCount] = useLazyQuery(GET_USER_COUNT, {
    variables: {
      apiKey,
      mpId,
    },
    fetchPolicy: "network-only",
    pollInterval: isLoggedIn ? 10000 : 0,
    onCompleted: async (result) => {
      const userCount = _.get(
        result,
        "getLoggedInUsersCountResponse.count",
        "0"
      );
      setBidderCount(userCount);
    },
  });
  const [getUserProfile] = useLazyQuery(GET_USER_PROFILE, {
    fetchPolicy: "network-only",
    variables: {
      apiKey,
      mpId,
    },
    onCompleted: async (result) => {
      const userProfile = _.get(result, "userResponse.user");
      homeDispatch({
        type: "SET_USER_LOCATION",
        args: {
          zipcode: userProfile.zipcode,
          address: userProfile.address1,
          city: userProfile.city,
          state: userProfile.state,
        },
      });
      homeDispatch({
        type: "SET_USER_ACCOUNTS",
        args: userProfile.accountList,
      });
    },
  });

  const [pageTitle, setPageTitle] = React.useState<string>();
  let location = useLocation();

  React.useEffect(() => {
    const activeKeys = getActiveKeys(pathname);
    setPageTitle(getPageTitle(activeKeys));
  }, [location.pathname]);
  React.useEffect(() => {
    if (isLoggedIn) {
      getWishListItems();
      getUserCount();
      getUserProfile();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn && pathname != "/login" && pathname != "/internal-login") {
    const redirectUrl = `${window.location.pathname}${window.location.search}`;
    if (redirectUrl === "/" || redirectUrl.includes("login")) {
      return <Redirect to={`/login`} />;
    }
    return (
      <Redirect
        to={`/login?redirectUrl=${window.location.pathname}${window.location.search}`}
      />
    );
  }

  return (
    <>
      {/* {pathname === "/" && <TopBanner />} */}
      <Layout css={layoutCss}>
        {/* {isLoggedIn && (
        <Alert
          message={
            "This is a preview of the next event. Vehicles will be added daily."
          }
          css={css`
          height: fit-content;
          margin-top: 66px;
          text-align: center;
          color: gray;
          background: blanchedalmond;
          span {
            font-size: 22px;
          }
          i {
            position unset;
            padding-right: 1%;
            font-size: 22px;
          }
          @media (max-width: 768px) {
            span {
              font-size: 16px;
              padding-left: 4%;
            }
            i {
              width: 10%;
              font-size: 16px;
            }
          }
        `}
          banner
          type="info"
          showIcon={true}
          closable
        />
      )} */}

        <Header
          css={css`
            ${headerCss}
            position: sticky;
            top: 0;
          `}
        >
          <Link to="/" css={logoLinkCss}>
            <img
              alt="logo"
              src={require("./images/rumbleon-black-logo.svg")}
              css={logoCss}
            ></img>
          </Link>
          <div
            css={css`
              padding-left: 20px;
            `}
          >
            <MainMenu />
          </div>
          <TopRightMenu bidderCount={bidderCount} />
        </Header>
        <Header
          css={css`
            ${headerMobileCss}
            position: sticky;
            top: 0;
          `}
        >
          <div
            css={css`
              display: ${isLoggedIn ? "flex" : "none"};
              align-items: center;
              justify-content: space-between;
            `}
          >
            <div
              css={css`
                display: flex;
                align-items: center;
                padding-left: 16px;
              `}
            >
              <MobileTopLeftMenu />
              <div css={logoMobileLinkCss}>
                <h1
                  css={css`
                    color: #2b2b2b;
                    font: normal normal bold 20px/28px Interstate;
                    padding-top: 6px;
                  `}
                >
                  {pageTitle}
                </h1>
              </div>
            </div>

            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <div
                css={css`
                  margin-top: -7px;
                `}
              >
                <BidderCount bidderCount={bidderCount} />
              </div>
              <Link to="/buy">
                <Icon
                  type="search"
                  theme="outlined"
                  css={css`
                    font-size: 21px !important;
                    margin-right: 0px !important;
                    margin-top: -4px;
                  `}
                />
              </Link>
              <MobileNotification />
            </div>
          </div>
        </Header>

        <Content css={contentCss}>{children}</Content>
        <Footer />
      </Layout>
    </>
  );
};

export default AppLayout;
