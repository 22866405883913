/* eslint-disable */

import * as React from "react";

type NotificationItem = {
  dateCreated: string;
  deviceType: number;
  listingId: number;
  markAsRead: boolean;
  notificationId: number;
  offerStatus: string;
  receiverUserId: number;
  subject: string;
};

type Action = { type: "SET_NOTIFICATION_LIST"; args: any };
type Dispatch = (action: Action) => void;
type State = {
  notificationList: NotificationItem[];
};
type NotificationProviderProps = {
  children: React.ReactNode;
};

const NotificationStateContext = React.createContext<State>({
  notificationList: [],
});

const NotificationDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const NotificationReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_NOTIFICATION_LIST":
      return {
        ...state,
        notificationList: action.args,
      };

    default:
      return state;
  }
};

const NotificationContextProvider = ({
  children,
}: NotificationProviderProps) => {
  const [state, dispatch] = React.useReducer(NotificationReducer, {
    notificationList: [],
  });

  return (
    <NotificationStateContext.Provider value={state}>
      <NotificationDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  );
};
const useNotificationState = () => {
  const context = React.useContext(NotificationStateContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationContext must be used within a NotificationContextProvider"
    );
  }
  return context;
};

const useNotificationDispatch = () => {
  const context = React.useContext(NotificationDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationDispatch must be used within a NotificationContextProvider"
    );
  }
  return context;
};

export {
  NotificationContextProvider,
  useNotificationState,
  useNotificationDispatch,
};
