import { Button, Divider, Drawer, message, notification } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Mutation, Query } from "../../generated/graphql";
import React, { useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";

import AlertFormModal from "../Profile/AlertList/AlertFormModal";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import { breakpoints } from "../../constants";
import { css } from "@emotion/core";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { formatCurrency } from "../../utils/format";
import getVehicleDetailUrl from "../../utils/getVehicleDetailUrl";
import { loader } from "graphql.macro";
import moment from "moment";
import { notificationSignalRClient } from "../../App";
import { useAuthInfo } from "../../utils/customHooks";
import { useNotificationDispatch } from "../../context/NotificationContext";

const RETRIEVE_NOTIFICATIONS = loader("./getNotifications.graphql");
const READ_NOTIFICATIONS = loader("./readNotification.graphql");

interface Props {
  visibility: boolean;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

const alertTypes = [
  { id: 1007, description: "Sold Confirmation Alerts" },
  { id: 1008, description: "Purchase Confirmation Alerts" },
  { id: 1004, description: "Received Offer Alerts" },
  { id: 1005, description: "Placed Offer Alerts" },
  { id: 1028, description: "Received Bid Alerts" },
  { id: 1029, description: "Placed Bid Alerts" },
  { id: 1006, description: "Outbid Alert" },
  { id: 1016, description: "Watchlist Item Live Alert" },
  { id: 3005, description: "Transaction Status Update" },
  // { id: 1002, description: "Wishlist Alert" },
];

type NotificationItem = {
  bidAmount: number;
  dateCreated: string;
  deviceType: number;
  listingId: number;
  markAsRead: boolean;
  notificationId: number;
  offerStatus: string;
  notificationTypeId: number;
  receiverUserId: number;
  subject: string;
  messageDto?: {
    vehicle: string;
  };
};

const NotificationSection: React.FC<Props> = ({ visibility, onClose }) => {
  const { apiKey, mpId, userId } = useAuthInfo();
  const history = useHistory();
  const [notificationItems, setNotificationItems] = useState<
    NotificationItem[]
  >([]);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedNotification, setSelectedNotification] = React.useState<any>();

  const dispatch = useNotificationDispatch();
  const [getNotifications, { data }] = useLazyQuery<Query>(
    RETRIEVE_NOTIFICATIONS,
    {
      fetchPolicy: "network-only",
      pollInterval: visibility ? 2000 : 5000,
      notifyOnNetworkStatusChange: true,
      variables: {
        apiKey: apiKey,
        mpId: mpId,
      },
      onCompleted: (result) => {
        const notifications: NotificationItem[] = _.get(
          data,
          "notificationListResponse.items",
          []
        );
        setNotificationItems(notifications);
        dispatch({
          type: "SET_NOTIFICATION_LIST",
          args: notifications,
        });
      },
      onError: () => {
        // notification.open({
        //   message: "Error",
        //   description: "Something went wrong while getting notifications",
        //   type: "warning",
        // });
      },
    }
  );
  const [updateNotification] = useMutation<Mutation>(READ_NOTIFICATIONS, {
    onCompleted: async (result) => {
      getNotifications();
    },
    onError: (error) => {
      message.error("Something went wrong updating error");
    },
  });
  React.useEffect(() => {
    getNotifications();
  }, []);

  React.useEffect(() => {
    const listingIdSet = new Set<number>();
    (notificationItems || []).forEach((notification) =>
      listingIdSet.add(notification.listingId)
    );

    const uniqueListingIds = Array.from(listingIdSet);
    if (uniqueListingIds.length > 0) {
      const subscriberIds: number[] = [];
      uniqueListingIds.forEach((listingId) => {
        const subscriberId = notificationSignalRClient.subscribeForUpdates(
          listingId,
          () => {
            getNotifications();
          }
        );
        subscriberIds.push(subscriberId);
      });
      return () =>
        subscriberIds.forEach((subscriberId) =>
          notificationSignalRClient.unsubscribeFromUpdate(subscriberId)
        );
    }
  }, [notificationItems]);

  // React.useEffect(() => {
  //   if (showModal === false) {
  //     onClose;
  //   }
  // }, [showModal]);

  async function readNotification(notificationIds: number[]) {
    try {
      await updateNotification({
        variables: {
          apiKey: apiKey,
          mpId: mpId,
          userId: userId,
          apiServicesNotificationReadInput: notificationIds.map(
            (notificationId) => {
              return { notificationId: notificationId, markAsRead: true };
            }
          ),
        },
      });
    } catch (error) {
      message.error("Something went wrong updating error");
    }
  }

  const handleAddAlert = (e: React.FormEvent, item: any) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(!showModal);
    setSelectedNotification(item);
  };

  function renderNotificationItems(
    notifications: NotificationItem[],
    markAsRead: boolean
  ) {
    if (notifications.length === 0) {
      return (
        <span
          css={css`
            font-size: 14px;
            padding-left: 10px;
          `}
        >
          {markAsRead ? "No cleared notifications" : "No new notifications"}
        </span>
      );
    }

    return notifications
      .filter((item) => item.markAsRead === markAsRead)
      .map((item) => {
        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onClose(e);
              history.push(
                getVehicleDetailUrl({
                  itemId: item.listingId,
                })
              );
            }}
            key={item.notificationId}
            css={css`
              margin-bottom: 25px;
              color: #2b2b2b;
              letter-spacing: 0.13px;
              cursor: pointer;
              position: relative;
              border-radius: 4px;
              padding: 10px;
              margin-bottom: 10px;
              &:hover {
                background: whitesmoke;
              }
            `}
          >
            <div
              css={css`
                display: block;
                font-size: 14px;
                margin-bottom: 4px;
                font-family: InterstateRegular;
                text-transform: uppercase;
                color: #4c4c4c;
              `}
            >
              <label>
                {alertTypes.map((notification) => {
                  if (notification.id === item.notificationTypeId) {
                    return notification.description;
                  }

                  return null;
                })}
              </label>
              <Tooltip placement="top-end" title={item.dateCreated}>
                <label
                  css={css`
                    float: right;
                    text-transform: uppercase;
                    font-size: 14px;
                  `}
                >
                  {moment(item.dateCreated).fromNow()}
                </label>
              </Tooltip>
            </div>

            <div
              css={css`
                display: inline-block;
              `}
            >
              <div
                onClick={(e) => {
                  history.push(
                    getVehicleDetailUrl({
                      itemId: item.listingId,
                    })
                  );
                  onClose(e);
                }}
              >
                <label
                  css={css`
                    font-weight: bold;
                    display: block;
                    font-size: 15px;
                    margin-bottom: 3px;
                    margin-right: 20px;
                    cursor: pointer;
                    text-transform: uppercase;
                    font-family: AktivGroteskMedium;
                  `}
                >
                  {item.messageDto ? item.messageDto.vehicle : null}{" "}
                  {item.bidAmount ? formatCurrency(item.bidAmount) : ""}
                </label>
              </div>

              <div
                css={css`
                  height: 24px;
                  display: flex;
                `}
              >
                <label
                  css={css`
                    font-size: 16px;
                    margin-bottom: 3px;
                    cursor: pointer;
                    font-family: AktivGroteskRegular;
                    font-size: 15px;
                    color: #4c4c4c;
                  `}
                >
                  {item.subject}
                </label>
                <div
                  css={css`
                    right: 10px;
                    margin-right: 0px;
                    position: absolute;
                  `}
                >
                  {markAsRead == false && (
                    <Tooltip placement="left" title={"Mark As Read"}>
                      <CloseIcon
                        css={css`
                          cursor: pointer;
                          width: 22px;
                          &:hover {
                            width: 20px;
                          }
                        `}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          readNotification([item.notificationId]);
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>

            <div
              css={css`
                padding: 2% 0%;
              `}
            >
              <Button
                onClick={(e) => handleAddAlert(e, item)}
                css={css`
                  background: #85ce06;
                  border: 1px solid #ffffff;
                  color: #ffffff;
                  border-radius: 22px;
                `}
                type="primary"
              >
                Add Alert
              </Button>
            </div>

            <Divider />
          </div>
        );
      });
  }

  const unreadNotifications = (notificationItems || []).filter(
    (obj) => obj.markAsRead === false
  );
  const readNotifications = (notificationItems || []).filter(
    (obj) => obj.markAsRead === true
  );

  return (
    <React.Fragment>
      {showModal && selectedNotification && (
        <AlertFormModal
          alertId={selectedNotification?.notificationTypeId}
          onCancel={() => setShowModal(false)}
        />
      )}
      <Drawer
        css={css`
          .ant-drawer-title {
            font-family: InterstateBold;
          }
          .ant-drawer-header {
            padding-bottom: 8px !important;
          }
          .ant-drawer-content-wrapper {
            width: 350px !important;
            @media (max-width: 560px) {
              width: 100% !important;
            }
          }
          .anticon {
            color: black;
            font-size: 18px;
            font-weight: bold;
            @media (min-width: ${breakpoints.md}) {
              display: none;
            }
          }
        `}
        title="NOTIFICATIONS"
        placement="right"
        closable={true}
        onClose={(e: any) => {
          onClose(e);
        }}
        visible={visibility}
        bodyStyle={{ letterSpacing: "0.13px", padding: 15, height: 64 }}
      >
        <div
          css={css`
            border-bottom: solid;
            border-top: solid;
            border-width: 1px;
            border-color: #e0e0e0;
            padding: 20px;
            margin-bottom: 15px;
            font-size: 14px;
            letter-spacing: 0.13px;
            margin-left: -20px;
            margin-right: -20px;
          `}
        >
          <Link
            to={`/profile?tab=alerts`}
            onClick={(e) => {
              onClose(e);
              const { pathname, search } = window.location;
              if (`${pathname}${search || ""}` !== `/profile?tab=alerts`) {
                history.replace(`/profile?tab=alerts`);
              }
            }}
          >
            <FontAwesomeIcon
              icon={faCog}
              style={{ top: "-0.5px", position: "relative" }}
            />
            <span
              css={css`
                margin-left: 10px;
                font-size: 16px;
                font-weight: medium;
                font-family: AktivGroteskMedium;
                color: #2b2b2b;
              `}
            >
              Alert settings
            </span>
          </Link>
          {unreadNotifications.length > 0 && (
            <span
              css={css`
                float: right;
                font-size: 16px;
                letter-spacing: 0.13px;
                font-weight: medium;
                font-family: AktivGroteskMedium;
              `}
              style={{
                color: unreadNotifications.length > 0 ? "#1B51CF" : "grey",
                cursor:
                  unreadNotifications.length > 0 ? "pointer" : "context-menu",
              }}
              onClick={
                unreadNotifications.length > 0
                  ? () =>
                      readNotification(
                        notificationItems.map((item) => item.notificationId)
                      )
                  : undefined
              }
            >
              Clear all
            </span>
          )}
        </div>

        <div
          css={css`
            border-bottom: solid;
            border-width: 1px;
            border-color: #e0e0e0;
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 20px;
            padding-right: 20px;
          `}
        >
          {renderNotificationItems(unreadNotifications, false)}
        </div>

        {/* <div
          css={css`
            padding-top: 20px;

            font-size: 16px;
            // color: #4c4c4c;
            letter-spacing: 0.13px;
          `}
        >
          <span
            css={css`
              font-weight: bold;
              font-family: InterstateBold;
              padding-left: 10px;
            `}
          >
            CLEARED NOTIFICATIONS
          </span>
          <div
            css={css`
              padding-top: 16px;
            `}
          >
            {renderNotificationItems(readNotifications, true)}
          </div>
        </div> */}
      </Drawer>
    </React.Fragment>
  );
};

export default React.memo(NotificationSection);
