/* eslint-disable */

import * as React from "react";

import { EventItem } from "../components/EventsPage/EventCard";
import { LiveCarousalEventType } from "../components/Home/components/LiveEventCarousal";
import { VehicleCardProp } from "../components/VehicleCard/VehicleCard";

type Action =
  | { type: "SET_VEHICLE_COUNT"; args: number }
  | { type: "SET_ROUTER_BACK"; args: boolean }
  | { type: "SET_MARKET_PLACES"; args: MarqueeMarketPlaceType[] }
  | { type: "SET_LIVE_EVENTS"; args: LiveCarousalEventType[] }
  | { type: "SET_EVENTS"; args: EventItem[] }
  | { type: "SET_RESERVE_MET_EVENTS"; args: VehicleCardProp[] }
  | { type: "SET_RECENTLY_ADDED"; args: VehicleCardProp[] }
  | { type: "SET_USER_LOCATION"; args?: UserLocation | null }
  | { type: "SET_USER_ACCOUNTS"; args: MiniUserType[] }
  | { type: "SET_USER_PROFILE"; args: UserAccount[] }
  | { type: "SET_SCROLL_HEIGHT"; args: number }
  | { type: "SET_RECENTLY_VIEWED_VEHICLES"; args: VehicleCardProp[] }
  | { type: "SET_TOTAL_EVENT_COUNT"; args: number };

type Dispatch = (action: Action) => void;

type HomeProviderProps = {
  children: React.ReactNode;
};

type MiniUserType = {
  accountName: string;
  zipcode: string;
  accountId: number;
  accountNumber: string;
};
type UserAccount = {
  accountName: string;
  accountId: number;
  accountNumber: string;
  enableSelling: boolean;
};

export type MarqueeMarketPlaceType = {
  logoUrl: string;
  marketplaceId: number;
  marketplaceName: string;
};

export type UserLocation = {
  zipcode: string;
  address: string;
  city: string;
  state: string;
};

type State = {
  vehicleCount?: number;
  totalEventCount?: number;
  eventCount?: number;
  marqueeMarketPlaces: MarqueeMarketPlaceType[];
  liveEvents: LiveCarousalEventType[];
  events: EventItem[];
  recentlyViewedVehicles: VehicleCardProp[];
  reserveMetVehicles: VehicleCardProp[];
  recentlyAddedVehicles: VehicleCardProp[];
  userLocation?: null | UserLocation;
  userAccounts: MiniUserType[];
  userProfile: UserAccount[];
  hasRouterBack: boolean;
  scrollHeight: number;
};
const initialState = {
  vehicleCount: undefined,
  hasRouterBack: false,
  totalEventCount: undefined,
  eventCount: undefined,
  marqueeMarketPlaces: [],
  liveEvents: [],
  events: [],
  recentlyViewedVehicles: [],
  reserveMetVehicles: [],
  recentlyAddedVehicles: [],
  userAccounts: [],
  userProfile: [],
  scrollHeight: 0,
};
const HomeStateContext = React.createContext<State>(initialState);

const HomeDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

const HomeReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case "SET_RESERVE_MET_EVENTS":
      return { ...state, reserveMetVehicles: action.args };
    case "SET_RECENTLY_ADDED":
      return { ...state, recentlyAddedVehicles: action.args };
    case "SET_TOTAL_EVENT_COUNT":
      return {
        ...state,
        totalEventCount: action.args,
      };

    case "SET_EVENTS":
      return {
        ...state,
        events: action.args.filter((item: any) => item.itemCount > 0),
        eventCount: action.args.length,
      };
    case "SET_VEHICLE_COUNT":
      return { ...state, vehicleCount: action.args };
    case "SET_MARKET_PLACES":
      return { ...state, marqueeMarketPlaces: action.args };
    case "SET_LIVE_EVENTS":
      return { ...state, liveEvents: action.args };
    case "SET_RECENTLY_VIEWED_VEHICLES":
      return { ...state, recentlyViewedVehicles: action.args };
    case "SET_USER_LOCATION":
      return { ...state, userLocation: action.args };
    case "SET_USER_ACCOUNTS":
      return { ...state, userAccounts: action.args };
    case "SET_USER_PROFILE":
      return { ...state, userProfile: action.args };
    case "SET_ROUTER_BACK":
      return { ...state, hasRouterBack: action.args };
    case "SET_SCROLL_HEIGHT":
      return { ...state, scrollHeight: action.args };
    default:
      return state;
  }
};

const HomeContextProvider = ({ children }: HomeProviderProps) => {
  const [state, dispatch] = React.useReducer(HomeReducer, initialState);
  return (
    <HomeStateContext.Provider value={state}>
      <HomeDispatchContext.Provider value={dispatch}>
        {children}
      </HomeDispatchContext.Provider>
    </HomeStateContext.Provider>
  );
};
const useHomeState = () => {
  const context = React.useContext(HomeStateContext);
  if (context === undefined) {
    throw new Error("useHomeContext must be used within a HomeContextProvider");
  }
  return context;
};

const useHomeDispatch = () => {
  const context = React.useContext(HomeDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useHomeDispatch must be used within a HomeContextProvider"
    );
  }
  return context;
};

export { HomeContextProvider, useHomeState, useHomeDispatch };
