import "react-app-polyfill/ie11";
import "core-js/es/map";
import "core-js/es/set";
import "ts-replace-all";
import "./index.css";

import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import environmentVariableList from "./config/env";

if (process.env.NODE_ENV === "production") {
  // remove console log in production build
  console.log = function () {};
}

TagManager.initialize({
  gtmId: environmentVariableList.REACT_APP_GTM_ID || "",
});

const container = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  container
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
