import {
  useModalFlowDispatch,
  useModalFlowState,
} from "../context/ModalContext";

import FlashSaleLogo from "../assets/images/flash-sale.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "antd";
import React from "react";
import ReactPlayer from "react-player";
import { css } from "@emotion/core";

const CashOfferVideoModal: React.FC = () => {
  const dispatch = useModalFlowDispatch();
  const state = useModalFlowState();

  const [playing, setPlaying] = React.useState(true);
  const { showCashOfferVideoModal } = state;
  return (
    <div
      css={css`
        background-color: #ffffff;
        box-shadow: 0px 0px 1px;
        .ant-modal {
          @media (min-width: 768px) {
            width: 600px !important;
            height: 400px !important;
          }
          background-color: lightgray;
        }
      `}
    >
      <Modal
        visible={showCashOfferVideoModal}
        closable
        footer={null}
        css={css`
          .ant-modal {
            .ant-modal-close-x {
              padding-bottom: 4% !important;
            }
            @media (min-width: 768px) {
              width: 600px !important;
              /* height: 500px !important; */
            }
            background-color: #ffffff;
          }
          .ant-modal-content {
            @media (min-width: 768px) {
              width: 600px !important;
              /* height: 600px !important; */
            }
            background-color: #ffffff;
          }
          .ant-modal-body {
            @media (min-width: 768px) {
              width: 600px !important;
              /* height: 600px !important; */
            }
            background-color: #ffffff;
          }
        `}
        style={{ width: "600px", height: "400px" }}
        onCancel={() => {
          setPlaying(false);
          console.log(playing);
          dispatch({
            type: "HIDE_ACCEPT_OFFER_SUCCESS_MODAL",
            args: {
              showCashOfferVideoModal: false,
            },
          });
        }}
        destroyOnClose
      >
        {!playing && (
          <div
            style={{ marginTop: "4%", fontWeight: "bold", fontSize: "16px" }}
          >
            Loading Something Important, Watch our short Video to understand and
            get familiar with our Brand new platform
          </div>
        )}

        {playing && (
          <div
            css={css`
              margin-top: 8%:
              margin-bottom: 8%;
              font-size: 22px;
              font-weight: bold;
              text-align: center;
              @media (max-width: 768px) {
                font-size: 18px;
              }
            `}
          >
            {/* DEALER DIRECT EXCHANGE EVENT */}
            {/* DEALER DIRECT FLASH SALE */}
            NO RESERVE SALE
          </div>
        )}

        {/* Uncomment for enabling video */}
        {/* <ReactPlayer
          // src={""}
          url={"https://youtu.be/I6AmBqdsVfE&feature=emb_title"}
          // url={"https://youtu.be/UK4ecXNdqt8&feature=emb_title"}
          autoPlay={false}
          playing={playing}
          controls={true}
          playIcon={<FontAwesomeIcon type="icon" icon="play" />}
          width={"100%"}
          onReady={() => setPlaying(true)}
          height={playing ? "300px" : "300px"}
        ></ReactPlayer> */}

        <img
          src={"https://sellimg.rumbleon.com/flash-sale.png"}
          width={"100%"}
          height={"100%"}
          css={css`
            background-color: #ffffff;
            box-shadow: 0px 0px 1px;
            object-fit: fill;
            background-position: center;
          `}
        />
      </Modal>
    </div>
  );
};

export default CashOfferVideoModal;
