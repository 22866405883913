import "../../../index.css";

import * as React from "react";

import AccountDetailView from "./AccountDetailView";
import { ProfileProp } from "../ProfilePage";
import _ from "lodash";
import { breakpoints } from "../../../constants";
import { css } from "@emotion/core";
import moment from "moment";

interface Props {
  profileData: ProfileProp;
}

const AccountInfoTabView: React.FC<Props> = ({ profileData }) => {
  return (
    <>
      <div
        css={css`
          @media (max-width: ${breakpoints.sm}) {
            padding: 16px !important;
            right: 16px !important;
            left: 16px !important;
            width: 90%;
          }
          height: auto;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 2px #00000029;
          border-radius: 4px;
          opacity: 1;
          left: 0px;
          right: 0px;
          margin: 0 auto;
          padding: 24px;
          margin-bottom: 20px;
          max-width: 608px;
        `}
      >
        <h1
          css={css`
            text-align: left;
            font: normal normal bold 20px/28px Interstate;
            letter-spacing: 0px;
            color: #4c4c4c;
            text-transform: uppercase;
            opacity: 1;
          `}
        >
          {`${profileData.firstName}` + " " + `${profileData.lastName}`}
        </h1>
        <p
          css={css`
            text-align: left;
            font: normal normal normal 16px/24px AktivGroteskRegular;
            letter-spacing: 0.13px;
            color: #2b2b2b;
            opacity: 1;
            word-break: break-all;
          `}
        >
          {profileData.email}
        </p>
        <p
          css={css`
            text-align: left;
            font: normal normal normal 16px/24px AktivGroteskRegular;
            letter-spacing: 0.13px;
            color: #2b2b2b;
            opacity: 1;
            word-break: break-all;
            text-transform: uppercase;
          `}
        >
          Member Since {moment(profileData.createdOn).format("MMMM DD, YYYY")}
        </p>
      </div>
      <div
        css={css`
          @media (max-width: ${breakpoints.sm}) {
            width: 90% !important;
          }
          left: 498px;
          width: 608px;
          height: auto;
          background: var(--white) 0% 0% no-repeat padding-box;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 0px 2px #00000029;
          border-radius: 4px;
          opacity: 1;
        `}
        style={{
          backgroundColor: "#ffffff",
          left: 0,
          right: 0,
          margin: "0 auto",
          padding: 24,
          marginBottom: 10,
        }}
      >
        <h1
          css={css`
            text-align: left;
            font: normal normal bold 20px/28px Interstate;
            letter-spacing: 0px;
            color: #4c4c4c;
            text-transform: uppercase;
            opacity: 1;
          `}
        >
          Account Information
        </h1>
        <AccountDetailView profileData={profileData} />
      </div>
    </>
  );
};

export default AccountInfoTabView;
