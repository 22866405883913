import * as React from "react";

import { Badge, Icon, Input, Menu } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  useAuthPopupDispatch,
  useAuthPopupState,
} from "../../../context/AuthPopupContext";

import BidderCount from "./BidderCount";
import NotificationSection from "../../Notifications/NotificationSection";
import { css } from "@emotion/core";
import environmentVariableList from "../../../config/env";
import getActiveKeys from "./getActiveKeys";
import { useNotificationState } from "../../../context/NotificationContext";

const menuSubItemCss = css`
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  height: 33px !important;
  line-height: 33px !important;
  font-family: AktivGroteskBold;
  font-weight: bold;
`;

const TopRightMenu: React.FC<{ bidderCount: number }> = ({ bidderCount }) => {
  const dispatch = useAuthPopupDispatch();
  const { isLoggedIn, firstName, lastName, username } = useAuthPopupState();
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [searchText, setSearchText] = React.useState("");
  let location = useLocation();
  const { notificationList } = useNotificationState();
  React.useEffect(() => {
    const { pathname } = location;
    const activeKeys = getActiveKeys(pathname);
    setSelectedKeys(activeKeys);
  }, [location.pathname]);

  const searchFunction = () => {
    if (searchText) {
      setSearchText("");
      history.push(`/buy?search=%22${searchText}%22`);
    }
  };
  const notificationCount = notificationList.filter((item) => !item.markAsRead)
    .length;

  if (!isLoggedIn) {
    return null;
  }
  return (
    <React.Fragment>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <BidderCount bidderCount={bidderCount} />

        <Input
          size="large"
          placeholder="Site Search"
          onPressEnter={() => searchFunction()}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          css={css`
            max-width: 200px;
            input {
              border: none !important;
              background-color: #eeeeee;
              color: #4c4c4c;
              padding-left: 40px !important;
              ::placeholder {
                color: #4c4c4c;
                opacity: 1;
              }
            }
          `}
          prefix={
            <Icon
              type="search"
              theme="outlined"
              css={css`
                font-size: 12px !important;
              `}
            />
          }
        />
        {/* Desktop */}
        <Menu
          selectedKeys={selectedKeys}
          mode="horizontal"
          css={css`
            font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
            font-weight: 400;
            color: #2b2b2b;
            border-bottom: none !important;
            line-height: 25px !important;
            .ant-menu-item-selected {
              border-bottom: none !important;
              color: rgba(0, 0, 0, 0.65);
            }
            .ant-menu-submenu {
              border: none !important;
              background: white !important;
              text-align: center;
            }
            .ant-menu-item,
            .ant-menu-submenu-title {
              padding: 0 10px !important;
            }
          `}
        >
          {isLoggedIn && (
            <Menu.Item
              css={css`
                border-bottom: 2px solid transparent !important;
              `}
              onClick={() => {
                setVisible(true);
              }}
            >
              <Badge
                style={{
                  backgroundColor: "#fff",
                  color: "#99120e",
                  boxShadow: "0 0 0 1px #99120e inset",
                }}
                count={notificationCount >= 99 ? "99+" : notificationCount}
              >
                <Icon
                  type="bell"
                  theme="filled"
                  css={css`
                    color: ${notificationCount > 0 && "#99120e"};
                    font-size: 20px !important;
                    padding-left: 10px;
                    margin-right: 0px !important;
                  `}
                />
              </Badge>

              <NotificationSection
                visibility={visible}
                onClose={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setVisible(false);
                }}
              />
            </Menu.Item>
          )}

          <Menu.SubMenu
            title={
              <div
                id="menu-icon"
                css={css`
                  border-radius: 50%;
                  background: purple;
                  display: flex;
                  flex: 1;
                  width: 35px;
                  height: 35px;
                  justify-content: center;
                  align-items: center;
                  border: 3px solid white;
                `}
              >
                {/* <Icon
                  type="user-filled"
                  css={css`
                    margin: 0 !important;
                    padding: 2%;
                    color: white;
                    font-weight: bold;
                  `}
                /> */}
                <h3
                  css={css`
                    margin: 0 !important;
                    padding: 2%;
                    color: white;
                    font-size: 14px;
                    font-weight: bold;
                  `}
                >
                  {firstName[0] + lastName[0]}
                </h3>
              </div>
            }
          >
            <Menu.Item
              key="profile"
              css={css`
                margin-bottom: 5px;
              `}
            >
              <Link
                to="/profile"
                css={[
                  css`
                    margin: 10px 0;
                    line-height: 1;
                    font-family: AktivGroteskBold;
                  `,
                ]}
              >
                <span
                  css={css`
                    margin: 0;
                    line-height: 1;
                    display: block;
                    font-size: 14px;
                    font-family: AktivGroteskBold;
                  `}
                >
                  {firstName} {lastName}
                </span>
                <span
                  css={css`
                    margin: 0;
                    line-height: 1;
                    font-weight: 400;
                    font-size: 12px;
                    font-family: AktivGroteskBold;
                  `}
                >
                  {username}
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="contact-us" css={menuSubItemCss}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={environmentVariableList.LINK_CONTACT_US}
              >
                {" "}
                Contact Us
              </a>
            </Menu.Item>
            <Menu.Item key="faq" css={menuSubItemCss}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={environmentVariableList.LINK_FAQ}
              >
                FAQs
              </a>
            </Menu.Item>
            <Menu.Item key="how-to-Videos" css={menuSubItemCss}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={environmentVariableList.LINK_HOW_TO_VIDEOS}
              >
                {" "}
                How-to Videos
              </a>
            </Menu.Item>

            <Menu.Item key="terms-and-condition" css={menuSubItemCss}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={environmentVariableList.LINK_TERMS_AND_CONDITION}
              >
                Terms & Conditions
              </a>
            </Menu.Item>

            <Menu.Item key="terms-and-condition" css={menuSubItemCss}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={environmentVariableList.AUCTION_POLICY}
              >
                Auction Policy
              </a>
            </Menu.Item>
            <Menu.Item
              key="logout-menu"
              id="logout-menu"
              css={menuSubItemCss}
              onClick={(_) => {
                history.push("/");
                dispatch({
                  type: "LOG_OUT",
                });
              }}
            >
              Logout
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    </React.Fragment>
  );
};

export default React.memo(TopRightMenu);
