import { Col, Dropdown, Menu } from "antd";

import { ALERT_TYPES } from "../ProfilePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { css } from "@emotion/core";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

interface Props {
  alertType?: boolean;
  onChange: (id: number) => void;
  selectedAlertType?: any;
}

export const AlertDropdown: React.FC<Props> = ({
  onChange,
  alertType,
  selectedAlertType,
}) => {
  const [selected, setSelected] = React.useState<{
    id: number;
    description: string;
  } | null>(null);
  const menu = (
    <Menu>
      {ALERT_TYPES.map((item) => {
        return (
          <Menu.Item
            key={item.id}
            onClick={() => {
              setSelected(item);
              onChange(item.id);
            }}
          >
            <p
              css={css`
                margin-bottom: 2px;
              `}
            >
              {item.description}
            </p>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  React.useEffect(() => {
    setSelected(selectedAlertType || null);
  }, [alertType, selectedAlertType]);

  return (
    <Col
      lg={15}
      css={css`
        background: #ffffff;
        display: flex;
      `}
    >
      <div
        css={css`
          width: 100%;
          height: 64px;
          background: #eeeeee 0% 0% no-repeat padding-box;
          border-radius: 4px 4px 0px 0px;
          padding: 12px 12px 0px 12px;
          opacity: 1;
          text-align: center;
          margin: 10px;
          border-bottom-style: solid;
          border-bottom-width: 1px;
        `}
      >
        {selected && (
          <p
            css={css`
              height: 16px;
              color: var(--light-charcoal);
              text-align: left;
              font: normal normal normal 12px/20px Interstate;
              letter-spacing: 0px;
              color: #4c4c4c;
              text-transform: uppercase;
              opacity: 1;
              margin-bottom: 0px;
              margin-top: -4px;
            `}
          >
            Alert type
          </p>
        )}

        <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
          <a
            className="ant-dropdown-link"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            style={{
              padding: selected ? 0 : 10,
            }}
            css={css`
              text-align: left;
              font: normal normal normal 16px AktivGroteskRegular;
              letter-spacing: 0.13px;
              color: #2b2b2b;
              opacity: 1;
              text-transform: none;
              float: left;
              display: flex;
              justify-content: space-between;
              width: 100%;
            `}
          >
            {selected ? (
              <span
                css={css`
                  padding-top: 8px;
                `}
              >
                {selected.description}
              </span>
            ) : (
              "Alert type"
            )}
            <FontAwesomeIcon
              icon={faCaretDown}
              style={{
                color: "#4C4C4C",
                fontSize: "12px",
                marginLeft: 10,
                marginTop: 5,
                marginRight: 10,
                float: "right",
              }}
            />
          </a>
        </Dropdown>
      </div>
    </Col>
  );
};
