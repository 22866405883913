import * as React from "react";

import { Badge, Icon, Menu } from "antd";

import NotificationSection from "../../Notifications/NotificationSection";
import { css } from "@emotion/core";
import getActiveKeys from "./getActiveKeys";
import { useAuthPopupState } from "../../../context/AuthPopupContext";
import { useLocation } from "react-router-dom";
import { useNotificationState } from "../../../context/NotificationContext";

const MobileNotification: React.FC = () => {
  const { isLoggedIn } = useAuthPopupState();
  const [visible, setVisible] = React.useState(false);
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  let location = useLocation();
  const { notificationList } = useNotificationState();
  React.useEffect(() => {
    const { pathname } = location;
    const activeKeys = getActiveKeys(pathname);
    setSelectedKeys(activeKeys);
  }, [location.pathname]);

  if (!isLoggedIn) {
    return null;
  }
  const notificationCount = notificationList.filter((item) => !item.markAsRead)
    .length;
  return (
    <React.Fragment>
      {/* Desktop */}
      <Menu
        selectedKeys={selectedKeys}
        mode="horizontal"
        css={css`
          font-family: aktiv-grotesk, arial, Helvetica, sans-serif !important;
          font-weight: 400;
          color: #2b2b2b;
          border-bottom: none !important;
          .ant-menu-item-selected {
            border-bottom: 2px solid transparent;
            color: rgba(0, 0, 0, 0.65);
          }
          .ant-menu-item,
          .ant-menu-submenu-title {
            padding: 0 16px;
          }
        `}
      >
        {isLoggedIn && (
          <Menu.Item
            css={css`
              border-bottom: 2px solid transparent !important;
            `}
            onClick={() => {
              setVisible(true);
            }}
          >
            <Badge
              style={{
                backgroundColor: "#fff",
                color: "#99120e",
                boxShadow: "0 0 0 1px #99120e inset",
              }}
              count={notificationCount >= 99 ? "99+" : notificationCount}
            >
              <Icon
                type="bell"
                theme="filled"
                css={css`
                  color: ${notificationCount > 0 && "#99120e"};
                  font-size: 21px !important;
                  margin-right: 0px !important;
                `}
              />
            </Badge>

            <NotificationSection
              visibility={visible}
              onClose={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setVisible(false);
              }}
            />
          </Menu.Item>
        )}
      </Menu>
    </React.Fragment>
  );
};

export default React.memo(MobileNotification);
