import "../../../index.css";

import * as React from "react";

import { Checkbox, Col, Row, Spin, Switch } from "antd";

import { AlertProp } from "../ProfilePage";
import _ from "lodash";
import { css } from "@emotion/core";

interface Props {
  checkList: number[];
  onCheckItem: (alertId: number) => {};
  updateAlertStatus: (isChecked: boolean, item: AlertProp) => void;
  item: AlertProp;
  alertName: string;
  updateAlertLoading: boolean;
}

const AlertItem: React.FC<Props> = ({
  checkList,
  onCheckItem,
  updateAlertStatus,
  item,
  alertName,
  updateAlertLoading,
}) => {
  const handleLangChange = (isSwitched: boolean) => {
    updateAlertStatus(isSwitched, item);
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Row
        css={css`
          padding-top: 18px;
          padding-left: 30px;
        `}
      >
        <Col
          xs={21}
          sm={21}
          md={21}
          lg={21}
          xl={21}
          css={css`
            background: #ffffff;
            text-align: left;
          `}
        >
          <Checkbox
            onChange={() => onCheckItem(item.alertId)}
            checked={checkList.indexOf(item.alertId) > -1}
            css={css`
              position: absolute;
              left: -15px;
            `}
          ></Checkbox>
          <div
            css={css`
              text-align: left;
              font: normal normal normal 16px AktivGroteskRegular;
              letter-spacing: 0.13px;
              color: #2b2b2b;
              opacity: 1;
              margin-left: 15px;
              margin-right: 15px;
            `}
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginRight: 10,
            }}
          >
            <h1
              css={css`
                text-align: left;
                font: normal normal bold 16px AktivGroteskBold;
                letter-spacing: 0.13px;
                color: #000000;
                opacity: 1;
              `}
            >
              {alertName}
            </h1>
            {item.deviceAddress}
          </div>
        </Col>

        <Col
          xs={3}
          sm={3}
          md={3}
          lg={3}
          xl={3}
          css={css`
            background: #ffffff;
            text-align: right;
            padding-right: 16px;
          `}
        >
          {updateAlertLoading ? (
            <Spin spinning={updateAlertLoading} />
          ) : (
            <Switch
              checked={item.statusId === 1}
              onChange={(isSwitched) => {
                handleLangChange(isSwitched);
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AlertItem;
