function getActiveKeys(pathname: string): string[] {
  if (pathname.includes("/events")) {
    return ["events"];
  } else if (pathname.includes("/watch-list")) {
    return ["watch-list"];
  } else if (pathname.includes("/market-place")) {
    return ["market-place"];
  } else if (pathname.includes("/run-list")) {
    return ["run-list"];
  } else if (pathname.includes("/history")) {
    return ["history"];
  } else if (pathname.includes("/buyer")) {
    return ["buyer"];
  } else if (pathname.includes("/seller")) {
    return ["seller"];
  } else if (pathname.includes("/buy")) {
    return ["buy"];
  } else if (pathname.includes("/item")) {
    return ["item", "buy"];
  } else if (pathname.includes("/profile")) {
    return ["profile"];
  } else if (pathname.includes("/")) {
    return ["home"];
  }
  {
    return [];
  }
}

export function getPageTitle(activityNames: string[]): string {
  if (!activityNames.length) {
    return "";
  }
  const pathName = activityNames[0];
  if (pathName === "events") {
    return "EVENTS";
  } else if (pathName === "item") {
    return "VEHICLE DETAILS";
  } else if (pathName === "watch-list") {
    return "WATCHLIST";
  } else if (pathName === "market-place") {
    return "MARKETPLACES";
  } else if (pathName === "run-list") {
    return "RUNLIST";
  } else if (pathName === "history") {
    return "HISTORY";
  } else if (pathName === "buyer") {
    return "BUYER ACTIVITY";
  } else if (pathName === "seller") {
    return "SELLER ACTIVITY";
  } else if (pathName === "buy") {
    return "BROWSE";
  } else if (pathName === "profile") {
    return "PROFILE";
  } else if (pathName === "home") {
    return "HOME";
  }
  return "";
}
export default getActiveKeys;
