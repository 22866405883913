import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";

import environmentVariableList from "./config/env";

class SignalRClient {
  private _url: string = "";
  private _updateMethod: string = "";
  private _subscriptionMethod: string = "";
  private _connection: HubConnection | null = null;
  private _subscribers: {
    id: number;
    itemId: number;
    callback: () => any;
  }[] = [];
  private _nextSubscriberId: number = 0;

  constructor(url: string, updateMethod: string, subscriptionMethod: string) {
    this._url = url;
    this._updateMethod = updateMethod;
    this._subscriptionMethod = subscriptionMethod;
  }

  connect: () => Promise<void> = async () => {
    this._connection = new HubConnectionBuilder().withUrl(this._url).build();
    try {
      await this._connection.start();
      this._connection.on(this._updateMethod, this._handleUpdate);
      this._connection.onclose((error) => {
        console.warn("SignalR connection was closed:", error);
      });
      if (this._subscribers.length > 0) {
        const itemIdSet = new Set<number>();
        this._subscribers.forEach((subscriber) =>
          itemIdSet.add(subscriber.itemId)
        );

        const uniqueItemIds = Array.from(itemIdSet);
        uniqueItemIds.forEach((itemId) => {
          this._connection
            ?.invoke(this._subscriptionMethod, itemId, true)
            .then()
            .catch((error) =>
              console.warn(
                "SignalR subscription method invokation failed:",
                error
              )
            );
        });
      }
    } catch (error) {
      console.warn("SignalR connection failed:", error);
    }
  };

  private _handleUpdate: (itemId: number) => void = (itemId) => {
    setTimeout(() => {
      this._subscribers.forEach((subscriber) => {
        if (subscriber.itemId === itemId) {
          subscriber.callback();
        }
      });
    }, environmentVariableList.SIGNALR_CALLBACK_INVOKING_DELAY_MS);

    setTimeout(() => {
      this._subscribers.forEach((subscriber) => {
        if (subscriber.itemId === itemId) {
          subscriber.callback();
        }
      });
    }, environmentVariableList.SIGNALR_CALLBACK_INVOKING_DELAY_MS * 2);
  };

  subscribeForUpdates: (itemId: number, callback: () => any) => number = (
    itemId,
    callback
  ) => {
    const subscriberId = this._nextSubscriberId;
    this._nextSubscriberId += 1;

    if (
      this._connection &&
      this._connection.state === HubConnectionState.Connected
    ) {
      const currentSubscribers = this._subscribers.filter(
        (subscriber) => subscriber.itemId === itemId
      );
      if (currentSubscribers.length === 0) {
        this._connection
          ?.invoke(this._subscriptionMethod, itemId, true)
          .then()
          .catch((error) =>
            console.warn(
              "SignalR subscription method invokation failed:",
              error
            )
          );
      }
    }

    this._subscribers.push({
      id: subscriberId,
      itemId,
      callback,
    });
    return subscriberId;
  };

  unsubscribeFromUpdate: (subscriberId: number) => void = (subscriberId) => {
    if (
      this._connection &&
      this._connection.state === HubConnectionState.Connected
    ) {
      const removingSubscriber = this._subscribers.find(
        (subscriber) => subscriber.id === subscriberId
      );
      if (removingSubscriber) {
        const otherSubscribers = this._subscribers.filter(
          (subscriber) =>
            subscriber.itemId === removingSubscriber.itemId &&
            subscriber.id !== subscriberId
        );
        if (otherSubscribers.length === 0) {
          this._connection
            ?.invoke(this._subscriptionMethod, removingSubscriber.itemId, false)
            .then()
            .catch((error) =>
              console.warn(
                "SignalR subscription method invokation failed:",
                error
              )
            );
        }
      }
    }
    this._subscribers = this._subscribers.filter(
      (subscriber) => subscriber.id !== subscriberId
    );
  };
}

export default SignalRClient;
